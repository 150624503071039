import {
  PrinterIcon,
  RectangleGroupIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import * as _ from 'lodash-es';
import { useState } from 'react';

import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import { Spinner } from '@/components/ui/spinner';
import {
  DRAWING_SUMMARY,
  STAT_FITTING_SYMOLS,
  STAT_LENGTH_SYMBOLS,
  STAT_EQUIPMENT_SYMBOLS,
  STAT_COMPLETE_DEGREE,
  STAT_INCOMPLETE_DEGREE,
} from '@/config';

import { exportDrawingReport } from '../api';
import { ExpandableSymbolGroup } from '../components/expandable-symbol-group';
import { ExportDrawingTool, EstimatingTool } from '../components/summary-tools';
import { useSymbolStatistics } from '../hooks/useSymbolStatistics';
import {
  useSymbolSubTotal,
  useRemoteSummaryReport,
} from '../hooks/useSymbolSummary';
import { useTableScrollHandler } from '../hooks/useTableScrollHandler';
import { BPSymbol, SymbolEditParams, SUMMARY_XP_FORMAT as FMT } from '../types';

type StatRowProps = {
  label: string;
  value: string | number;
  suffix?: string;
};

const StatRow = ({ label, value, suffix }: StatRowProps) => (
  <li className="border-b border-gray-300 py-1 px-0 ">
    <span className="inline-block w-28 text-right">{label}</span>
    <span className="pl-2 text-sm font-bold">
      {value}
      {suffix || ''}
    </span>
  </li>
);

type SummaryModalProps = {
  symbols: BPSymbol[];
  context: SymbolEditParams;
} & ModalOpenProps;

/**
 * Drawing symbols overview report
 */
export const SummarySubTotal = ({
  isOpen,
  isOnStage,
  lazyCloseHandler,
  symbols,
  context,
}: SummaryModalProps) => {
  const [downloading, setDownloading] = useState(false);
  const [togglePreview, setTogglePreview] = useState(false);

  // ====== main logic with hooks ======

  const { subTotalGroups } = useSymbolSubTotal(symbols || []);
  // Monitor table scrolling to fix group header!
  useTableScrollHandler(subTotalGroups, !togglePreview);

  const {
    fittingCount,
    lengthCount,
    equipmentCount,
    completionDegree,
    inCompletionDegree,
  } = useSymbolStatistics(symbols || []);

  const { projectId, fileId, pageId, jobId, projectName } = context;
  const { symbolGroups } = useRemoteSummaryReport(
    projectId,
    fileId,
    pageId,
    jobId,
  );

  // console.log(symbolGroups);

  // ====== end of hooks ======

  const suffix: { [P in FMT]: string } = {
    EXCEL: 'xlsx',
    CSV: 'csv',
    PDF: 'pdf',
    PROJECT: 'xlsx', // @2024/11/06
  };
  const downloadReportAs = async (format: string) => {
    setDownloading(true);
    // console.log(`downloading ${format} file ...`);
    const response = await exportDrawingReport(context, format);
    const url = window.URL.createObjectURL(response);
    const link = document.createElement('a');
    const singlePageFile = `${context.fileName}_${context.pageName}`;
    const finalReportName =
      format === FMT.T ? _.snakeCase(projectName) : singlePageFile;
    link.href = url;
    // link.setAttribute('href', url);
    link.download = `${finalReportName}.${suffix[format as FMT]}`;
    // link.setAttribute('download', `${reportFullName}.${suffix[format]}`);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => setDownloading(false));
  };

  const previewReportToggleHandler = () => {
    setTogglePreview(!togglePreview);
  };

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={DRAWING_SUMMARY}
      containerClass="full-screen-summary"
      tools={
        <>
          <button
            type="button"
            className="btn-title-tool mr-1"
            onClick={previewReportToggleHandler}
          >
            {togglePreview ? (
              <Square2StackIcon className="size-4 text-blue-600 inline-block" />
            ) : (
              <RectangleGroupIcon className="size-4 text-blue-600 inline-block" />
            )}
            <span className="text-sm px-1">
              {togglePreview ? 'Report' : 'Symbol'} View
            </span>
          </button>
          {!downloading && <ExportDrawingTool exportTo={downloadReportAs} />}
          <button disabled type="button" className="btn-title-tool mr-1">
            <PrinterIcon className="size-4 text-blue-600 inline-block" />
            <span className="text-sm px-1">Print</span>
          </button>
          {downloading && (
            <div className="h-30 w-20 mx-1">
              <Spinner size="sm" className="mt-1 inline-block" />
              <span className=" ml-1 text-xs inline-block text-gray-600 align-bottom">
                Exporting...
              </span>
            </div>
          )}
          <EstimatingTool />
        </>
      }
    >
      <div className="flex size-full py-2">
        {/* left column */}
        <div className="left-panel w-56 bg-gray-100 border-r border-gray-300 py-2 pl-0 border-t">
          <h3 className="border-b-2 border-green-600 mb-2 py-1 text-center">
            Summary Statistics:
          </h3>
          <ul className="text-xs leading-5">
            <StatRow
              label="Symbols total:"
              value={symbols ? symbols.length : 0}
            />
            <StatRow label={STAT_FITTING_SYMOLS} value={fittingCount} />
            <StatRow label={STAT_LENGTH_SYMBOLS} value={lengthCount} />
            <StatRow label={STAT_EQUIPMENT_SYMBOLS} value={equipmentCount} />
            <StatRow
              label={STAT_COMPLETE_DEGREE}
              value={completionDegree}
              suffix="%"
            />
            <StatRow
              label={STAT_INCOMPLETE_DEGREE}
              value={inCompletionDegree}
              suffix="%"
            />
            {/* <StatRow label="Manual symbol:" value={0} /> */}
            {/* <StatRow label="Mature degree:" value={0} /> */}
          </ul>
        </div>
        {/* right column */}
        {/* Add additional pb-8 to reveal last row of symbol groupd regarding issue: #610 - 2024/07/10 */}
        <div className="right-panel-table w-full summary-column p-2 border-t border-gray-300 pb-8">
          {/* === BACKEND GENERATED REPORT */}
          {togglePreview &&
            symbolGroups.map((row, index) => (
              <div
                key={index}
                data-group={row.item_id_group}
                className="summary-row w-full bg-white"
              >
                {/* Category Title */}
                {row.isCategory && (
                  <>
                    <h2 className="text-center font-medium text-base border-b border-gray-300 py-1">
                      {row.item_id_group}
                    </h2>
                    <div className="w-full flex text-center border-b border-gray-300 font-medium">
                      <span className="summary-report-cell w-72">Item ID</span>
                      <span className="summary-report-cell w-20">System</span>
                      <span className="summary-report-cell w-20">Quantity</span>
                      <span className="summary-report-cell w-20">Units</span>
                    </div>
                  </>
                )}
                {/** row */}
                {!row.isCategory && (
                  <div className="w-full flex text-center border-b border-gray-300">
                    <span className="summary-report-cell w-72">
                      {row.item_id_group}
                    </span>
                    <span className="summary-report-cell w-20">
                      {row.system}
                    </span>
                    <span className="summary-report-cell w-20">
                      {row.quantity}
                    </span>
                    <span className="summary-report-cell w-20">
                      {row.units}
                    </span>
                  </div>
                )}
              </div>
            ))}
          {/* === INTERACTIVE SYMBOLS REPORT === */}
          {!togglePreview &&
            subTotalGroups.map((group, index) => (
              <div
                key={index}
                className="subtotal-group p-1 w-full bg-white mb-6 relative"
              >
                {/* PART_1: group header, could be fixed while close to the top */}
                <div className="sticky-group-header bg-white">
                  {/* group title row */}
                  <h2 className="text-center font-medium text-base border-b border-gray-300 py-1">
                    {group.title}
                  </h2>
                  {/* grid fields row */}
                  <div className="w-full flex text-center border-b border-gray-300">
                    {group.fields.map((f, i) => (
                      <span
                        key={f}
                        className={clsx(
                          'inline-block p-1 border-r border-gray-300 text-xs overflow-x-hidden',
                          i == 0 ? 'w-36' : 'w-20',
                        )}
                      >
                        {f}
                      </span>
                    ))}
                  </div>
                </div>
                {/* PART_2: group rows */}
                {group.groups.map((sg, i) => (
                  <ExpandableSymbolGroup
                    key={i}
                    group={sg}
                    dimensionFields={group.dimension}
                    closeHandler={lazyCloseHandler}
                  />
                ))}
                {/* PART_3: total row */}
                <div className="w-full flex text-center border-b border-gray-300 focus:outline-none">
                  <span className="inline-block p-1 border-r border-gray-300 text-xs overflow-x-hidden w-36">
                    Total
                  </span>
                  <span className="inline-block p-1 border-r border-gray-300 text-xs overflow-x-hidden w-20">
                    {group.count}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </AnimateModal>
  );
};
