import clsx from 'clsx';
import { useEffect, useRef, ChangeEvent } from 'react';

import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import { Spinner } from '@/components/ui/spinner';
import {
  CREATE_NEW_PROJECT,
  INPUT_LENGTH_LIMIT,
  CREATE_PROJECT_HINT,
  CREATE_PROJECT_LABEL,
} from '@/config';

import useProjectCreate from '../hooks/useProjectCreate';
import { resetSliderPage } from '../state';

export type NewProjectModalProps = ModalOpenProps & {
  type?: string;
  projectCreatedHandler: () => void;
};

// ************  NEW PROJECT MODAL ************
export const NewProjectSimpleModal = ({
  isOpen,
  isOnStage,
  lazyCloseHandler,
  projectCreatedHandler,
}: NewProjectModalProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // --- interact with project API ---
  const saveProjectSucces = () => {
    lazyCloseHandler();
    projectCreatedHandler();
    // FIXME: reset slider to initial status
    // @2023/02/01
    resetSliderPage();
  };
  const {
    sending,
    projectName,
    onProjectSaveHandler,
    onProjectCreationCancel,
    onProjectNameChangeHandler,
  } = useProjectCreate(saveProjectSucces);
  // --- end of project hook ---

  const validProjectName = projectName.length > 0;

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onProjectNameChangeHandler(event.target.value);
  };

  const saveProjectHandler = () => {
    if (!projectName) return;
    if (sending) return;

    onProjectSaveHandler();
  };

  const cancelProjectHandler = () => {
    onProjectCreationCancel();
    lazyCloseHandler();
  };

  useEffect(() => {
    if (!isOnStage) return;
    inputRef.current?.focus();
  }, [isOnStage]);

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={CREATE_NEW_PROJECT}
    >
      {/* project name */}
      <label className="color-primary text-xs font-semibold mt-10">
        {CREATE_PROJECT_LABEL}
        <input
          ref={inputRef}
          type="text"
          name="projectName"
          className="frame-input mt-1"
          placeholder={CREATE_PROJECT_HINT}
          maxLength={INPUT_LENGTH_LIMIT}
          value={projectName}
          onChange={inputChangeHandler}
        />
      </label>
      {/* button row */}
      <div className="flex gap-x-10 justify-between mx-0 mt-9">
        {/* close modal */}
        <button
          className="btn-red-border color-red font-semibold flex-1 red-shadow"
          onClick={() => cancelProjectHandler()}
        >
          Cancel
        </button>
        {/* save button */}
        <button
          className={clsx(
            'btn-green flex-1 leading-5 text-base',
            validProjectName
              ? 'bg-green shadow-md'
              : 'bg-disabled cursor-default',
          )}
          disabled={!projectName.length}
          onClick={saveProjectHandler}
        >
          {sending ? 'Saving...' : 'Create'}
          {sending && (
            <span className="pt-0.5 pl-2">
              <Spinner size="sm" variant="light" />
            </span>
          )}
        </button>
      </div>
    </AnimateModal>
  );
};
