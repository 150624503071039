import * as _ from 'lodash-es';
import { useState } from 'react';

import { PROJECT_CREATE_ERROR } from '@/config';
import { useAuth } from '@/lib/auth';
import { useNotificationStore } from '@/stores/notifications';

import { saveNewProject, mockCompanyID as MC } from '../api';

const useProjectCreate = (onProjectSuccessCallback: () => void) => {
  const [sending, setSending] = useState(false);
  const [projectName, setProjectName] = useState('');

  const user = useAuth();
  const { addNotification } = useNotificationStore();

  const onProjectSuccessHandler = () => {
    setSending(false);
    setProjectName('');
    onProjectSuccessCallback();
  };

  const onProjectSaveHandler = async () => {
    setSending(true);
    try {
      const companyId = _.get(user, 'company_roles[0].company.id');
      const safeCompanyId = companyId ? `${companyId}` : MC;
      const userName = _.get(user, 'name') || 'UNNO';
      await saveNewProject(projectName, safeCompanyId, userName);
      onProjectSuccessHandler();
    } catch (error: any) {
      console.error(error);
      addNotification({
        type: 'error',
        title: PROJECT_CREATE_ERROR,
      });
    }
  };

  const onProjectNameChangeHandler = (value: string) => {
    // FIXME: trim space to save, https://taksoai.atlassian.net/browse/TAK-317
    // allowing space between words
    // @2023/08/21
    const lastTwo = value.slice(-2);
    if (lastTwo === '  ') {
      console.warn(`## double space not allowed!`);
      return;
    }
    const noHeadSpace = value.trimStart();
    setProjectName(noHeadSpace);
  };
  const onProjectCreationCancel = () => setProjectName('');

  return {
    sending,
    projectName,
    onProjectSaveHandler,
    onProjectCreationCancel,
    onProjectNameChangeHandler,
  };
};

export default useProjectCreate;
