import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

export type ModalComnProps = {
  isOpen: boolean;
  isOnStage: boolean;
  lazyCloseHandler: (lastType?: string) => void;
};

type UserModalExtraProps = {
  companyId: string;
};

export type TKGeneModalProps = {
  commons: ModalComnProps;
  extra?: UserModalExtraProps;
};

/**
 * Better place to hold all the common dialog properties
 * @2023/08/24
 */
export type ConfirmExtraProps = {
  action?: string;
  /** for single deletion */
  deleteMessage?: string;
  /** for multiple deletion */
  discardMessage?: string;
  createHandler?: () => void;
  deleteHandler?: () => void;
  deletesHandler?: () => void;
};

export type DeleteModalProps = ModalOpenProps & ConfirmExtraProps;

export type ModalOpenProps = {
  isOpen: boolean;
  isOnStage: boolean;
  lazyCloseHandler: (type?: string) => void;
};

type AnimateModalProps = ModalOpenProps & {
  title: string;
  children: React.ReactNode;
  containerClass?: string; // define the dialog width, height
  tools?: JSX.Element; // optional title row tools
};

export const AnimateModal = ({
  isOpen,
  isOnStage,
  lazyCloseHandler,
  title,
  children,
  containerClass = 'project-dialog-simple',
  tools,
}: AnimateModalProps) => (
  <Dialog
    open={isOpen}
    onClose={() => lazyCloseHandler()}
    as="div"
    className={clsx(
      'backdrop-layer fixed inset-0 z-100 overflow-y-auto flex justify-center items-center',
      { 'bg-translucent': isOpen === true },
    )}
  >
    {/* dialog content */}
    <div
      className={clsx(
        'flex flex-col bg-white p-8 modal-dialog fade rounded-lg',
        { 'show-up': isOnStage === true },
        containerClass,
      )}
    >
      {/* top title */}
      <Dialog.Title className="color-primary text-md font-semibold flex justify-between">
        {title}
        <div className="tool-set flex">
          {tools}
          <button
            type="button"
            onClick={() => lazyCloseHandler()}
            className="btn-icon-only p-1 focus:outline-none size-7"
          >
            <XMarkIcon className="size-5 heroicon-sw-3" color="#555085" />
          </button>
        </div>
      </Dialog.Title>
      {/* html content */}
      {children}
    </div>
  </Dialog>
);
