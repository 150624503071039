import clsx from 'clsx';

import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import {
  LOCK_FILE_MODAL_TITLE,
  LOCK_FILE_MODAL_CONTENT,
  UNLOCK_FILE_CONTENT,
  UNLOCK_FILE_TITLE,
} from '@/config';

export type LockFileModalProps = ModalOpenProps & {
  fileLocked: boolean;
  fileLockedHandler: () => void;
};

/** LOCK FILE MODAL */
export const LockFileModal = ({
  isOpen,
  isOnStage,
  fileLocked,
  lazyCloseHandler,
  fileLockedHandler,
}: LockFileModalProps) => (
  <AnimateModal
    isOpen={isOpen}
    isOnStage={isOnStage}
    lazyCloseHandler={lazyCloseHandler}
    title={fileLocked ? UNLOCK_FILE_TITLE : LOCK_FILE_MODAL_TITLE}
  >
    {/* text content */}
    <p className="text-sm my-12">
      {fileLocked ? UNLOCK_FILE_CONTENT : LOCK_FILE_MODAL_CONTENT}
    </p>
    {/* button row */}
    <div className="flex gap-x-10 justify-between mx-0 mt-6">
      {/* close modal */}
      <button
        className="btn-red-border color-red font-semibold flex-1 red-shadow"
        onClick={() => lazyCloseHandler()}
      >
        Cancel
      </button>
      {/* save button */}
      <button
        className={clsx(
          'btn-green flex-1 leading-5 text-base bg-green shadow-md',
        )}
        onClick={() => {
          fileLockedHandler();
          lazyCloseHandler();
        }}
      >
        Apply
      </button>
    </div>
  </AnimateModal>
);
