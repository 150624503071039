/**
 * Pure functions to dispatch events
 *
 * @date 2024/02/27
 */

import { FeatureAttrDetail, BatchReviewProps } from '../panels/panel-props';
import { TKEvent as EVT, BPSymbol } from '../types';

/**
 * auto save on symbolc corrected
 *
 * EVENT RELAY PATH:
 *
 * `SAVETOTALCORRECTIONS` ->
 *   useTopNavigatorBar(get parameters and pass it to next hook) ->
 *    `SAVECHANGES` ->
 *      useDomCallback(perform symbols saving) ->
 *       `REFRESHSYMBOLS` ->
 *         useSymbolStore(refreshPageSymbols)
 *
 * @date 2023/11/23,29
 */
export const notifySymbolSavingInSilent = () => {
  // detail: true means using `silent` saving mode
  const event = new CustomEvent(EVT.SAVETOTALCORRECTIONS, {
    detail: true,
  });
  document.dispatchEvent(event);
};

/**
 * Dispatch silent saving event, handled by `useTopNavBar`
 */
export const saveAllCorrectionsHandler = () => {
  // detail: false means non-silent mode
  const event = new CustomEvent(EVT.SAVETOTALCORRECTIONS, {
    detail: false,
  });
  document.dispatchEvent(event);
};

/**
 * Tell topNavBar to close spinner and popup message if have success!
 *
 * `useTopNavigatorBar` will handle this event!
 *
 * @param successLength
 * @returns
 */
export const lazyNotifyWithDetail = (successLength: number) => {
  const handler = () => {
    const detail = { detail: successLength };
    // notify `useTopNavBar`
    const success = new CustomEvent(EVT.SAVECHANGESCOMPLETED, detail);
    document.dispatchEvent(success);
  };
  // no changes to save! popup message immediately!
  if (successLength == 0) {
    return handler();
  }
  // lazy notificaiton to wait for the spinner close
  setTimeout(handler, 500);
};

/**
 * === NOT IN USE ===
 * Reset global `change` state for symbol through:
 * `useSymbolStore() -> useSymbolsEditStore() -> clearChanges()`
 * @deprecated not in use
 */
export const muteSymbolsChanged = () => {
  document.dispatchEvent(new Event(EVT.MUTE_SYMBOL_CHANGE));
};

/**
 * Notify symbol store to refresh page symbols from backend and mute the changes.
 * This is an easy way to keep the symbol store synchronized with backend !
 *
 * @param pageId current page
 * @returns void
 */
export const refreshPageSymbolsAfterSaving = (pageId: string) => {
  // notify symbol store to refresh all the symbols
  const event = new CustomEvent(EVT.REFRESHSYMBOLS, { detail: pageId });
  document.dispatchEvent(event);
};

export const refreshPageSymbolsAfterPatch = (pageId: string) => {
  const event = new CustomEvent(EVT.RELAODSYMBOLS, { detail: pageId });
  document.dispatchEvent(event);
};

export const addSymbolToStore = (pageId: string, symbol: BPSymbol) => {
  const customEvt = new CustomEvent(EVT.ADDSYMBOL_TO_STORE, {
    detail: { symbol, page: pageId },
  });
  document.dispatchEvent(customEvt);
};

export const clearSymbolsFromStore = (
  pageId: string,
  symbols: (BPSymbol | null)[],
  hasChanged = true,
) => {
  // notify `useFileDetails` to clear cache
  const detail = { pageId, symbols, hasChanged };
  const customEvt = new CustomEvent(EVT.CLEARSYMBOL_FROM_STORE, {
    detail,
  });
  document.dispatchEvent(customEvt);
};

export const clearSymbolFromStore = (
  pageId: string,
  symbolId: string,
  hasChanged = true,
) => {
  // TODO: figure out if new feature being deleted from context menu
  // if so, check the map changed or unchanged

  // notify `useFileDetails` to clear cache
  const detail = { pageId, symbolId, hasChanged };
  const customEvt = new CustomEvent(EVT.CLEARSYMBOL_FROM_STORE, { detail });
  document.dispatchEvent(customEvt);
};

/**
 * Send open feature panel event to `useFeatureSelectionPanel` hook
 * @param props
 */
export const openFeaturePropertiesPanel = (props: FeatureAttrDetail) => {
  const customEvt = new CustomEvent(EVT.OPEN_FEATURE_PROPERTIES, {
    detail: props,
  });
  document.dispatchEvent(customEvt);
};

/**
 * Send open batch panel event to `useFeatureSelectionPanel` hook
 * @param props
 */
export const openBatchSelectionPanel = (props: BatchReviewProps) => {
  const customEvt = new CustomEvent(EVT.OPEN_BATCH_SELECTIONS, {
    detail: props,
  });
  document.dispatchEvent(customEvt);
};

export const closeSidePanel = () => {
  document.dispatchEvent(new Event(EVT.CLOSE_SIDE_PANEL));
};
