/**
 * Confirmation modal for leaving drawing symbols changes
 * @2023/05/04
 */

import clsx from 'clsx';

import { AnimateModal, DeleteModalProps } from '@/components/ui/modal';
import { NOSAVING_LEFT_TITLE, NOSAVING_LEFT_MESSAGE } from '@/config';

export const LeaveChangesConfirm = ({
  isOpen,
  isOnStage,
  lazyCloseHandler,
  deleteHandler,
}: DeleteModalProps) => (
  <AnimateModal
    isOpen={isOpen}
    isOnStage={isOnStage}
    lazyCloseHandler={lazyCloseHandler}
    title={NOSAVING_LEFT_TITLE}
  >
    {/* text content */}
    <p className="text-sm my-12">{NOSAVING_LEFT_MESSAGE}</p>
    {/* button row */}
    <div className="flex gap-x-10 justify-between mx-0 mt-6">
      {/* close modal */}
      <button
        className="btn-red-border color-red font-semibold flex-1 red-shadow"
        onClick={() => {
          lazyCloseHandler();
          deleteHandler && deleteHandler();
        }}
      >
        Yes
      </button>
      {/* save button */}
      <button
        className={clsx(
          'btn-green flex-1 leading-5 text-base bg-green shadow-md',
        )}
        onClick={() => {
          lazyCloseHandler();
        }}
      >
        No
      </button>
    </div>
  </AnimateModal>
);
