import {
  ClockIcon,
  PauseCircleIcon,
  CheckCircleIcon,
  CogIcon,
  DocumentDuplicateIcon,
  ExclamationTriangleIcon,
  LockClosedIcon,
  PuzzlePieceIcon,
  Squares2X2Icon,
} from '@heroicons/react/24/solid';

import hvacAndPipeDrawing from '@/assets/icons/default_file_icon_purple.svg';
import hvacFileIconGreen from '@/assets/icons/hvac_file_icon_green.svg';
import pipeFileIconBlue from '@/assets/icons/pipe_file_icon_blue.svg';

import { FILECONTYPE, FILESTATUS, GE, JOBSTATUS, PAGESTATUS } from '../types';

export const JobStatusIcon: { [key: string]: JSX.Element } = {
  [JOBSTATUS.PENDING]: <PauseCircleIcon color="#FBE173" className="size-6" />,
  [JOBSTATUS.PROCESSING]: <ClockIcon color="#FBE173" className="size-6" />,
  [JOBSTATUS.COMPLETE]: <CheckCircleIcon color="#86DC3D" className="size-6" />,
  [JOBSTATUS.ERROR]: <ExclamationTriangleIcon color="red" className="size-6" />,
  [PAGESTATUS.PROCESSING]: <ClockIcon color="#FBE173" className="size-6" />,
  [PAGESTATUS.ZOOMIFY]: <Squares2X2Icon color="#FBE173" className="size-6" />,
};

export const IconStatus: GE = {
  [FILESTATUS.COMPLETE]: (
    <CheckCircleIcon className="size-6 text-green-600 inline-block" />
  ),
  [FILESTATUS.EXTRACTING_PAGES]: (
    <CogIcon className="size-6 text-blue-600 inline-block" />
  ),
  [FILESTATUS.GENERATING_THUMBNAILS]: (
    <CogIcon className="size-6 text-blue-600 inline-block" />
  ),
  [FILESTATUS.PENDING]: (
    <ClockIcon className="size-6 text-blue-600 inline-block" />
  ),
  [FILESTATUS.LOCKED]: (
    <LockClosedIcon className="size-6 text-red-600 inline-block" />
  ),
};

export const FileTypes: GE = {
  [FILECONTYPE.PDF]: (
    <div className="p-1 bg-gray-500 rounded-full">
      <DocumentDuplicateIcon className="size-6 text-white" />
    </div>
  ),
  [FILECONTYPE.DEFAULT]: (
    <div className="p-1 bg-gray-500 rounded-full">
      <PuzzlePieceIcon className="size-6 text-white" />
    </div>
  ),
  [FILECONTYPE.PIPE]: (
    <img src={pipeFileIconBlue} alt="file-type" className="size-8" />
  ),
  [FILECONTYPE.HVAC]: (
    <img src={hvacFileIconGreen} alt="file-type" className="size-8" />
  ),
  [FILECONTYPE.HPMIX]: (
    <img src={hvacAndPipeDrawing} alt="file-type" className="size-8" />
  ),
};
