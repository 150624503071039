import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';

import { useBrushCorrectSetting } from '../hooks/useBrushCorrectSetting';
import { ImageRaidoButton } from '../inputs';
import { TKSystem } from '../types';

type BrushSettinProps = ModalOpenProps & {
  systems: TKSystem[];
};

export const BrushSettingModal = ({
  isOpen,
  isOnStage,
  lazyCloseHandler,
  systems,
}: BrushSettinProps) => {
  const ubc = useBrushCorrectSetting(systems);

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={'Brush Correction Settings'}
      containerClass="w-auto max-h-full overflow-y-auto "
    >
      {/* DUCT - PIPE */}
      <div className="flex my-0 w-80 mt-8">
        <ImageRaidoButton
          label="Duct"
          value="DUCT"
          name="symbolType"
          selectedValue={ubc.symbolType}
          selectChangeHandler={ubc.symbolTypeChangeHandler}
        />
        <ImageRaidoButton
          label="Pipe"
          value="PIPE"
          name="symbolType"
          selectedValue={ubc.symbolType}
          selectChangeHandler={ubc.symbolTypeChangeHandler}
        />
      </div>
      {/* SYSTEM select */}
      <label className="color-primary text-xs font-semibold block mt-8 mb-0">
        System
        <select
          name="systemType"
          className="block w-full px-2 py-1 mt-1 text-sm frame-input"
          value={ubc.selectedSystem}
          onChange={ubc.systemChangeHandler}
        >
          <option value="">Select system</option>
          {systems.map((ds) => (
            <option key={ds.short} value={ds.short}>
              {ds.fullName}({ds.short})
            </option>
          ))}
        </select>
      </label>
      {/* COLOR select */}
      <h2 className="color-primary text-xs font-semibold mt-8 mb-2">
        Choose Color
      </h2>
      <div className="flex my-0 w-64 mb-8">
        {/* RED */}
        <ImageRaidoButton
          value="red"
          name="colorType"
          selectedValue={ubc.renderColor}
          selectChangeHandler={ubc.colorTypeChangeHandler}
        />
        {/* ORANGE */}
        <ImageRaidoButton
          value="orange"
          name="colorType"
          selectedValue={ubc.renderColor}
          selectChangeHandler={ubc.colorTypeChangeHandler}
        />
        {/* YELLOW */}
        <ImageRaidoButton
          value="yellow"
          name="colorType"
          selectedValue={ubc.renderColor}
          selectChangeHandler={ubc.colorTypeChangeHandler}
        />
        {/* GREEN */}
        <ImageRaidoButton
          value="green"
          name="colorType"
          selectedValue={ubc.renderColor}
          selectChangeHandler={ubc.colorTypeChangeHandler}
        />
        {/* PURPLE */}
        <ImageRaidoButton
          value="purple"
          name="colorType"
          selectedValue={ubc.renderColor}
          selectChangeHandler={ubc.colorTypeChangeHandler}
        />
        {/* BLUE */}
        <ImageRaidoButton
          value="blue"
          name="colorType"
          selectedValue={ubc.renderColor}
          selectChangeHandler={ubc.colorTypeChangeHandler}
        />
      </div>
      {/* button row */}
      <div className="flex gap-x-10 justify-between mx-0 mt-6">
        {/* close modal */}
        <button
          className="btn-red-border color-red font-semibold flex-1 red-shadow"
          onClick={() => lazyCloseHandler()}
        >
          Cancel
        </button>
        {/* save button */}
        <button
          className="btn-green flex-1 leading-5 text-base bg-green shadow-md"
          onClick={() => {
            lazyCloseHandler();
            ubc.applyBrushSelectHandler();
          }}
        >
          Apply
        </button>
      </div>
    </AnimateModal>
  );
};
