export const PRODUCT_APP_NAME = 'TaksoAI Tool';
export const PRODUCT_SLOGAN_WORDS = 'Revolutionize Mechanical Takeoff';
export const SIGNIN_WELCOME_WORDS = 'Welcome to your new takeoff tool';
export const RUN_ALGO_BUTTON = 'Run';
export const DELETE_SELECTED_PAGES = 'Discard';
export const PROCESSING_BUTTON = 'Starting AI process';
export const AI_RUNNING_INFO =
  'AI processing started, you will receive an email when completed.';
export const EXCEED_TABS_LIMIT = 'Exceed the open tabs limit 6!';
export const FILES_UPLOAD_SUCCESS = 'New files uploaded!';
export const NO_FILE_UPLOADED =
  'No file uploaded, you may want to rename it and retry?';
export const CREATE_NEW_PROJECT = 'Create a New Project';
export const CREATE_PROJECT_HINT = 'Enter Project Name';
export const CREATE_PROJECT_LABEL = 'Project name';
export const LOCK_DRAWING_BUTTON_LABEL = 'Lock to Prevent Changes';
export const LOCK_FILE_MODAL_TITLE = 'Lock takeoff to prevent changes';
export const UNLOCK_FILE_TITLE = 'Unlock Drawing';
export const LOCK_FILE_MODAL_CONTENT =
  'If you lock takeoff you will not be able to edit the drawing until unlocked.';
export const UNLOCK_FILE_CONTENT = 'Unlock the drawing to allow editing!';
export const NO_PAGES_AVAILABLE = 'No pages available yet.';
export const REACH_MAX_UPLOADS = 'You have reached the single upload limit';
export const CLICK_TO_BROWSE = 'Click to browse';
export const LABEL_BUTTON_UPLOADING = 'Uploading...';
export const LABEL_UPLOAD_NEW = 'Upload New File';
export const LABEL_DROP_DOCS = 'Drop your documents here, or select';
export const NEW_DUCT_MODAL_TITLE = 'Add a Duct Fitting';
export const NEW_PIPE_MODAL_TITLE = 'Add a Pipe Fitting';
export const NEW_PIPE_LENGTH_TITLE = 'Add a Straight Pipe Length';
export const NEW_DUCT_LENGTH_TITLE = 'Add a Straight Duct Length';
export const NEW_PLUMBING_TITLE = 'Add a Plumbing';
export const CORRECT_DUCT_LENGTH_TITLE = 'Correct Duct Length';
export const CORRECT_PIPE_LENGTH_TITLE = 'Correct Pipe Length';
export const SWITCH_TO_FEATURE_MODIY = 'Switched to MODIFY mode!';
export const SWITCH_TO_FEATURE_TRANSLATE = 'Swithched to TRANSLATE mode!';
export const SWITCH_TO_FEATURE_AUTO = 'Switched to AUTO mode!';
export const CORRECT_DUCT_MODAL_TITLE = 'Correct Duct Fitting';
export const CORRECT_PIPE_MODAL_TITLE = 'Correct Pipe Fitting';
export const CORRECT_EQUIPMENT = 'Correct Equipment';
export const NEW_EQUIPMENT_TITLE = 'Add Equipment';
export const EMPTY_ZOOMIFY_URL = 'zoomify url is empty!';
export const EMPTY_MAP_WIDTH = 'Page Width is empty!';
export const EMPTY_MAP_HEIGHT = 'Page Height is empty!';
export const EMPTY_MAP_DATA =
  'Drawing has nothing to review, start AI job first in project page!';
export const PAGE_ADD_TO_QUE = 'One Page Added to AI Job Queue!';
export const PAGE_REMOVE_FROM_QUE = 'One Page Removed from AI Job Queue!';
export const PAGE_ALL_TO_QUE = 'All New Pages Added to Job Queue!';
export const PAGE_ALL_DE_QUE = 'All New Pages Removed from Job Queue!';
export const PAGE_ALL_STARTED_AI = 'All selected pages started AI job!';
export const PAGE_X_STARTED_AI = (n: number) =>
  `${n} page${n > 1 ? 's' : ''} started AI job`;
export const AI_PARAMETERS_REQUIRED =
  'Please input parameters value to start AI job!';
export const AI_JOB_RESTARTED = 'AI job restarted, please wait for new result!';
export const POLLINGSTART_MSG = 'Please wait for new file extraction!';
export const POLLING_AIJOB_MSG =
  'Please wait for AI job to complete, you will receive an email when completed.';
export const AIJOB_DONE_MSG =
  'AI job done! Click page card to check the symbols!';
export const AI_JOB_RUNNING = 'This page has already started an AI job!';
export const DRAWING_SUMMARY = 'Drawing Takeoff Report';
export const SELECT_SYSTEM = 'Select System';
export const SELECT_DUCT_SYSTEM = 'Select Duct System';
export const SELECT_PIPE_SYSTEM = 'Select Pipe System';
export const SELECT_MORE_SYSTEM = 'Select More System';
export const ADD_CUTEOM_FITTING = 'Add a Custom Fitting';
export const FILE_EXTRACTION_FAILURE = 'Oops, File Extraction Failure!';
export const FILE_EXTRACTION_COMPLETED =
  'Drawing pages generated, please select page cards to start AI job!';
export const DELETE_CONFIRM_TITLE = 'Confirm deleteion';
export const DELETE_CONFIRM_MESSAGE = 'Are you sure to delete this page?';
export const DELETE_PROJECT_MESSAGE =
  'Are you sure to delete this project PERMANENTLY? This is IRREVERSIBLE!';
export const ARCHIVE_PROJECT_MESSAGE =
  'Are you sure you want to delete this project';
export const EQUIPMENT_SETTINGS = 'Equipment Settings';
export const NOSAVING_LEFT_TITLE = 'Discard Changes';
export const NOSAVING_LEFT_MESSAGE =
  'Are you sure to leave page without saving changes?';
export const SET_DRAWING_TYPE =
  'Set drawing type first before requesting a new AI job!';
export const SELECT_AI_MODULE =
  'Select at least one AI module before proceeding!';
export const NO_JOB_ATTACHED = 'No job run, run job first from top button!';
// Used in `useProjectDetails` while token has expired
export const UNAUTHORIZED_ACCESS =
  'Accessing unauthorized resources, nothing could be presented';
export const REGISTER_SETUP = 'Your account has been setup successfully!';
export const REGISTER_CONFIRM =
  'Confirm registration from your email before signing in!';
export const INPUT_MAX_6 = 'Max at 6 chars';
export const INPUT_MAX_18 = 'Max at 18 chars';
export const PROJECT_CREATE_ERROR = 'Got error while creating new project!';
export const PROJECT_BOOKMARK_SUCCESS = 'Project Bookmarked!';
export const PROJECT_BOOKMARK_CANCEL = 'Project bookmark canceled!';
export const MESSAGE_SERVER_CONNECTED =
  'AI job messages notification ready to use!';

// sort button toggling label
export const SORT_LAST_MODIFY = 'SORT: LAST MODIFIED';
export const SORT_LTST_MODIFY = 'SORT: LATEST MODIFIED';

// SUCCESS MESSAGES
export const LOGIN_SUCCESS_MESSAGE =
  'Login in success, opening your projects...';
export const INVITATION_CONFIRMED = 'Invitation confirmed, you can login now!';
export const SINGLE_FILE_UPLOAD_SUCCESS = 'uploaded successfully';

// ERROR MESSAGES
export const BACKEND_EXCEPTION_ERROR =
  'Oops, something wrong in our system, please let admin know!';
export const ERROR_MESSAGE_FOR_401 = 'Session expired, please login again!';
export const MESSAGE_SERVER_UNAVAILABLE =
  'Message server not accessible, will retry in 10 secs!';
export const MESSAGE_SERVER_CLOSED = 'Message server connection closed!';
export const NO_COMPANY_ROLE_ASSIGNED =
  'No company role assigned to you, please contact admin!';
export const NO_PROJECTS_HINT = 'No projects assigned';
export const NO_MORE_PROJECTS = 'No more projects available to use!';
export const ITEM_CATALOGUES_LOAD_FAILED = 'Item Catalogues loading failed!';
export const AI_JOB_FAILED = 'AI job failed!';
export const UPLOAD_REPETITION_MESSAGE = 'already uploaded this project!';
export const UPLOAD_FAILURE_MESSAGE = '## create Presigned URL failed!';
export const UPLOAD_REMOVE_FAILED_MESSAGE =
  'Remove failed files to start over!';

// All - sub item types in dropdown select
export const ALL_DEFAULT_ITEM = 'Select an item type to begin';
export const ALL_DEFAULT_SYTM = 'All Systems';
export const EMPTY_OPTION_LABEL = 'Empty';

// file card
export const FILE_EXTRACTING_MESSAGE = 'File Preparing...';

// for statistics modal
export const STAT_FITTING_SYMOLS = 'Fitting Symbols:';
export const STAT_LENGTH_SYMBOLS = 'Length Symbols:';
export const STAT_EQUIPMENT_SYMBOLS = 'Equipment Symbols:';
export const STAT_COMPLETE_DEGREE = 'Completion Degree:';
export const STAT_INCOMPLETE_DEGREE = 'Incomplete symbols:';

// button titles
export const ARCHIVE_PROJECT_BUTTON_TITLE = 'Delete Project';
export const DELETE_PROJECT_BUTTON_TITLE = 'Delete Project Permanently';

// feature panel button toast message
export const SYMBOL_COPIED_MESSAGE = 'Symbol Copied!';
export const SYMBOL_CONFIRMED_MESSAGE = 'Symbol Confirmed!';
export const SYMBOL_DELETED_MESSAGE = 'Symbol Deleted';

// summary report menu item
export const SUMMARY_TOOL_MENU_CSV = 'CSV- Page';
export const SUMMARY_TOOL_MENU_XLS = 'Excel- Page';
export const SUMMARY_TOOL_MENU_PRJ = 'Excel- Project';
