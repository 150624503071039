import { TOKEN_EXPIRY_LENGTH } from '@/config';

import { diffFromNow, singleTruthNow } from './format';

const storagePrefix = 'taksoai_tool_';

/**
 * Cache user input system
 *
 * @param system system value
 * @param project project id
 */
export const saveCustomSystem = (system: string, project = '1') => {
  const key = `${storagePrefix}system`;
  const systemStr = getStoredItem(key);
  const systemOptions = systemStr ? JSON.parse(systemStr) : [];
  const finalSystems = [
    { label: system, value: system, project },
    ...systemOptions,
  ];
  saveItem(key, JSON.stringify(finalSystems));
};

/**
 *
 * @returns
 */
export const getSavedCustomSystems = () => {
  const key = `${storagePrefix}system`;
  // systems could be null at first use!!
  const systemStr = getStoredItem(key);
  return systemStr ? JSON.parse(systemStr) : [];
};

/**
 * Cache job progress
 * @param jobId
 * @param progress
 * @param name AI module name
 */
export const saveProgress = (
  jobId: string,
  progress: number,
  name: string,
): void => {
  saveItem(
    `${storagePrefix}progress`,
    JSON.stringify({ jobId, progress, name }),
  );
};

/**
 * Get cached job progress
 *
 * @param jobId
 * @returns
 */
export const getLastProgress = (
  jobId: string | null,
): {
  /** job jid */
  jobId: string;
  /** progress value */
  progress: number;
  /** AI module name */
  name: string;
} => {
  const empty = { jobId: '', progress: 0, name: '' };
  if (!jobId) return empty;
  const jobProgress = getStoredItem(`${storagePrefix}progress`);
  // `jobProgress` is empty at first use! so, offer a {} to avoid crash!
  return JSON.parse(jobProgress) || empty;
};

export const getStoredItem = (key: string) =>
  window.localStorage.getItem(key) as string;
export const saveItem = (key: string, value: string) =>
  window.localStorage.setItem(key, value);
export const deleteItem = (key: string) => window.localStorage.removeItem(key);

const storage = {
  clearToken: () => {
    deleteItem(`${storagePrefix}token`);
  },
  setToken: (token: string) => {
    saveItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  getToken: () => JSON.parse(getStoredItem(`${storagePrefix}token`)),
  getLoginTime: () => getStoredItem(`${storagePrefix}timestamp`),
  setLoginTime: () => {
    const timestamp = singleTruthNow();
    saveItem(`${storagePrefix}timestamp`, timestamp);
  },
  isTokenExpired: () => {
    const timestamp = getStoredItem(`${storagePrefix}timestamp`);
    if (!timestamp) return false;
    return diffFromNow(timestamp) > TOKEN_EXPIRY_LENGTH;
  },
};

export default storage;
