import * as SentryClient from '@sentry/browser';
import * as SentrySDK from '@sentry/react';

import { APP_DOMAIN, API_CTX, API_URL, isUsingSentry } from '@/config';

const targetDomains: (string | RegExp)[] = [APP_DOMAIN, API_URL + API_CTX];

export const initMonitoring = (useCanvas = true) => {
  if (!isUsingSentry) return;

  const commonPlugins = [
    SentrySDK.browserTracingIntegration(),
    SentrySDK.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [API_URL], // capture http detail for debugging - 2024/08/16
    }),
  ];
  // The following is all you need to enable canvas recording with Replay
  // FIXME: this plugin cause a higher CPU usage (20 ~ 30%) in 'medium' quality,
  // when it changed to 'low', it become better running around ~15%.
  //  - @2024/08/06
  const canvasPlugin = SentrySDK.replayCanvasIntegration({
    quality: 'low',
  });

  const integrations = useCanvas
    ? [...commonPlugins, canvasPlugin]
    : commonPlugins;

  SentrySDK.init({
    dsn: 'https://d429398477369116fcc885e137b22a00@o4507493839208448.ingest.us.sentry.io/4507498977689600',
    // FIXME: suppress unhandled low level error from axios(createError.js)!
    // @2024/08/16
    ignoreErrors: [/^Request failed with status code 401$/],
    // beforeSend(event, hint) {
    //   const error = hint.originalException as Error;
    //   if (
    //     error &&
    //     error.message &&
    //     error.message.match(/^Request failed with status code 401$/)
    //   ) {
    //     return null;
    //   }
    //   return event;
    // },
    integrations,
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Performance Monitoring
    tracesSampleRate: 0.1, //  1: Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: targetDomains,
    // Session Replay: 0.1 for production, 1 for development
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  console.log(`## Sentry SDK inited!`);
};

/**
 * Report warning message! - low level error, ignorable
 * @param message exception message
 */
export const reportErrorWithMessage = (message: string) => {
  if (!isUsingSentry) return;
  SentryClient.captureMessage(message);
};

/**
 * Report serious error, such as crash! - high level error, EMERGENCY!!
 *
 * @log Ignore `token` related exception - 22024/10/23
 * @param error javascript error object
 * @returns
 */
export const reportException = (error: Error) => {
  if (!isUsingSentry) return;
  if (error.message.includes('token')) return;
  SentryClient.captureException(error);
};
