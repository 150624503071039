import { BPPage, BPSymbol } from '../types';

import {
  TabState,
  SymbolState,
  RibbonToolState,
  SymbolsFilteringPayload,
  HomeProjectsPayload,
  AlgoInputPayload,
  ProjectDetailPayload,
} from './common';

export enum PROJECTXTRACTIONTYPE {
  START_LOADING_PROJECT = 'START_LOADING_PROJECT',
  START_LOADING_BP = 'START_LOADING_BLUEPRINTS',
  END_LOADING_BP = 'COMPLETE_BP_LOADING',
  SET_PROJECT_DETAIL = 'SET_PROJECT_DETAIL',
  SET_PROJECT_FIELD = 'SET_PROJECT_FIELD',
  SET_BLUEPRINTS = 'SET_BLUEPRINTS',
  SET_FILE_COUNT = 'SET_FILE_COUNT',
  SET_PAGES_FOR_BP = 'SET_PAGES_FOR_BP',
  RELOAD_PAGES = 'RELOAD_BLUEPRINTS_PAGES',
  CLEAN_UP_PAGES = 'REMOVE_FILES_&_PAGES_TO_EMPTY',
  FINISH_LOADING_ANYWAY = 'STOP_LOADING_ON_PROJECT',
}

export interface ProjectXtractAction {
  type: PROJECTXTRACTIONTYPE;
  payload: ProjectDetailPayload;
}

export enum ALGOINPUTACTTYPE {
  SET_CEILING_HEIGHT = 'SET_CEILING_HEIGHT',
  SET_FLOOR_HEIGHT = 'SET_FLOOR_HEIGHT',
  SET_SCALE_VALUE = 'SET_SCALE_VALUE',
  SET_UNIT_TYPE = 'SET_UNIT_TYPE',
  SHOW_INPUTS_BY_PAGE = 'SHOW_ALGO_INPUT_VALUES',
  /** NOTE: but do not update `unit`, its set from project */
  RESET_INPUTS_AFTER_JOB = 'RESET_INPUT_VALUES',
}

export interface AlgoInputAction {
  type: ALGOINPUTACTTYPE;
  payload: AlgoInputPayload;
}

export enum HOMEPROJECTSACTYPE {
  LOAD_PROJECTS = 'LOAD_PROJECTS',
  EXIT_LOADING = 'EXIT_LOADNG',
  INIT_PROJECTS = 'INIT_PROJECTS',
  UPDT_PROJECTS = 'ENCHANCE_PROJECTS',
  GOTO_PROJECTS = 'GOTO_PROJECTS',
  /** sort project with modify time */
  REVS_PROJECTS = 'REVERSE_PROJECTS',
}

export interface HomeProjectsAction {
  type: HOMEPROJECTSACTYPE;
  payload: HomeProjectsPayload;
}

export enum SYMBOLFILTERTYPE {
  INIT_ITEMS = 'INIT_ITEM_TYPES_FROM_SYMBOL',
  INIT_SYSTEMS = 'INIT_SYSTEMS',
  /** find category & item type from `topFilter` */
  FIND_CAT_AND_TYPE = 'FIND_CATEGORY_AND_ITEM_TYPE',
  /** Reveal a selected symbol in review panel */
  SET_CAT_AND_TYPE = 'SET_CATEGORY_AND_ITEM_TYPE',
  SET_CATEGORY = 'SELECT_CATEGORY_GROUP',
  SET_ITEM_TYPE = 'SELECT_ITEM_TYPE',
  SET_SYSTEM_TYPE = 'SELECT_SYSTEM_TYPE',
  SET_ALL_COMPLETED = 'TOGGLE_ALL_COMPLETED',
  SET_USER_COMPLETED = 'TOGGLE_USER_COMPLETED',
  SET_ORDERED_FIELD = 'SET_ORDERED_FIELD',
  DEL_SYMBOL = 'DELETE_SYMBOL_BY_ID',
  CFM_SYMBOL = 'CONFIRM_SYMBOL_BY_ID',
  SHOW_ALL = 'SHOW_ALL_SYMBOLS_IN_DRAWING',
  CLEAN_UP = 'CLEAN_UP_PANEL',
}

export interface SymbolsFilteringAction {
  type: SYMBOLFILTERTYPE;
  payload: SymbolsFilteringPayload;
}

export enum RIBBONHANDLETYPE {
  INIT = 'SET_FILE_ID',
  RESET = 'SET_RIBBON_GROUPS',
}

export enum SymbolsHandleType {
  EXIT = 'EXIT_LOADING', // close loading flag
  LOAD = 'LOAD_SYMBOLS', // open loading flag
  INIT = 'SET_SYMBOLS', // set key/value to display
  DISPOSE = 'DISPOSE_SYMBOLS', // remove the key from store when closed tab
  CLEAR = 'CLEAR_ALL_SYMBOLS', // clear all the symbols after return to project page
  // *** editor mode ***
  ADD = 'ADD_SYMBOL', // draw a new symbol in Editor mode
  UPDATE = 'UPDATE_SYMBOLS', // modify symbols in Editor mode
  SAVE = 'SAVE_SYMBOLS', // save to backend in Editor mode
  DELETE = 'DELETE_SYMBOLS', // delete symbols in Editor mode
}

export enum TabHandleType {
  OPEN = 'TAB_OPEN',
  CLOSE = 'TAB_CLOSE',
  SELECT = 'TAB_SELECT',
  INIT = 'PAGES_INIT',
  LOCK = 'DRAWING_LOCK',
  CHANGED = 'SYMBOLS_CHAGNED',
  UPDATE = 'UPDATE_PAGE',
}

// ********** RIBBON ACTIONS HANDLING **********

export interface RIBBONTOOLACTION {
  type: RIBBONHANDLETYPE;
  payload: RibbonToolState;
}

/** NOT IN USE ... */
export const createRibbonInitAction = (fileId: string): RIBBONTOOLACTION => ({
  type: RIBBONHANDLETYPE.INIT,
  payload: { fileId },
});

export const createRibbonChangeAction = (
  drawingId: string,
  ribbonMode?: string,
): RIBBONTOOLACTION => ({
  type: RIBBONHANDLETYPE.RESET,
  payload: { drawingId, ribbonMode },
});

// ********** SYMBOLS ACTIONS HANDLING **********

export interface SymbolsCRUDAction {
  type: SymbolsHandleType;
  payload: SymbolState;
}

/**
 * Stop spinner after symbols loading finished
 *
 * @param pageId
 * @returns
 */
export const createLoadExitAction = (pageId: string): SymbolsCRUDAction => ({
  type: SymbolsHandleType.EXIT,
  payload: { page: pageId },
});

/**
 * Loading jobitems of current drawing in two cases:
 * - first load at drawing openning
 * - auto-saving completed, reload all the symbols at `refreshPageSymbols`
 *
 * @param pageId
 * @param reload if need to mute spinner
 *
 * @returns
 */
export const createLoadSymbolsAction = (
  pageId: string,
  reload?: boolean,
): SymbolsCRUDAction => ({
  type: SymbolsHandleType.LOAD,
  payload: { page: pageId, reload },
});

/**
 * add symbols of one page to symbol store
 * @param pageId
 * @param symbols
 * @returns
 */
export const createSymbolsInitAction = (
  pageId: string,
  symbols: BPSymbol[],
): SymbolsCRUDAction => ({
  type: SymbolsHandleType.INIT,
  payload: { page: pageId, symbols },
});

/**
 * remove symbols of one page from symbol store
 * @param pageId closed page tab
 * @returns
 */
export const createSymbolsDisposeAction = (
  pageId: string,
): SymbolsCRUDAction => ({
  type: SymbolsHandleType.DISPOSE,
  payload: { page: pageId },
});

/**
 * clear all the symbols from symbol store, `page` is a must have prop for payload!
 * @returns
 */
export const createSymbolsClearAction = (): SymbolsCRUDAction => ({
  type: SymbolsHandleType.CLEAR,
  payload: { page: '' }, // empty payload
});

/**
 * Delete one symbol from root cache
 * @param pageId
 * @param symbolId
 * @returns
 */
export const createSymbolDeleteAction = (
  pageId: string,
  symbolId: string,
): SymbolsCRUDAction => ({
  type: SymbolsHandleType.DELETE,
  payload: { page: pageId, symbol: symbolId },
});

/**
 * Add one symbol to root cache
 * @param pageId
 * @param symbol
 * @returns
 */
export const createSymbolAdditionAction = (
  pageId: string,
  symbol: BPSymbol,
): SymbolsCRUDAction => ({
  type: SymbolsHandleType.ADD,
  payload: { page: pageId, symbol },
});

/**
 * Modify one symbol with changes on postion, size, details.
 * @param pageId
 * @param symbol
 * @returns
 */
export const createSymbolUpdateAction = (
  pageId: string,
  symbol: BPSymbol,
): SymbolsCRUDAction => ({
  type: SymbolsHandleType.UPDATE,
  payload: { page: pageId, symbol },
});

/**
 * Update multiple symbols in symbol store
 * @param pageId
 * @param symbols
 * @returns
 */
export const createBatchUpdateAction = (
  pageId: string,
  symbols: BPSymbol[],
): SymbolsCRUDAction => ({
  type: SymbolsHandleType.UPDATE,
  payload: { page: pageId, symbols },
});

/**
 * Delete multiple symbols from store
 * @param pageId
 * @param symbols
 * @returns
 */
export const createBatchDeleteAction = (
  pageId: string,
  symbols: BPSymbol[],
): SymbolsCRUDAction => ({
  type: SymbolsHandleType.DELETE,
  payload: { page: pageId, symbols },
});

// ********** TAB ACTIONS HANDLING **********

export interface TabNavigateAction {
  type: TabHandleType;
  payload: TabState;
}

export const createOpenTabAction = (tab: TabState): TabNavigateAction => ({
  type: TabHandleType.OPEN,
  payload: tab,
});

export const createCloseTabAction = (tabId: string): TabNavigateAction => ({
  type: TabHandleType.CLOSE,
  payload: { id: tabId },
});

export const createSelectPageAction = (tabId: string): TabNavigateAction => ({
  type: TabHandleType.SELECT,
  payload: { id: tabId },
});

/**
 * Create sidebar thumbnails with `pages`, it comes from `relatives` in SPA mode
 *
 * @param pages
 * @returns
 */
export const createInitPagesAction = (pages: BPPage[]): TabNavigateAction => ({
  type: TabHandleType.INIT,
  payload: { pages, id: '' },
});

export const createLockPageAction = (
  tabId: string,
  locked: boolean,
): TabNavigateAction => ({
  type: TabHandleType.LOCK,
  payload: { id: tabId, locked },
});

/** NOT IN USE */
export const createPageChangeAction = (
  tabId: string,
  changed: boolean,
): TabNavigateAction => ({
  type: TabHandleType.CHANGED,
  payload: { id: tabId, changed },
});

export const createUpdatePageAction = (page: BPPage): TabNavigateAction => ({
  type: TabHandleType.UPDATE,
  payload: { id: page.id, page },
});
