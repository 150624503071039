/**
 * Equipment creation and correction logic
 *
 * @2023/05/30
 */

import { useState, useEffect } from 'react';

import { getSymbolDetailBy } from '../state';
import {
  CATALOGUE_TYPE,
  FittingEntity,
  FITTING_TYPE,
  TKEvent,
  findItemByType,
} from '../types';

export const useEquipmentModal = (onClose: () => void, itemId?: string) => {
  const [eqmtName, setEqmtName] = useState('');
  const [eqmtSubtype, setEqmtSubtype] = useState('');
  const [sNumber, setSNumber] = useState('');
  const [identifier, setIdentifier] = useState('');

  /**
   * New equipment tag payload
   * @returns
   */
  const createFeatureEntity = (): FittingEntity => {
    const equipmentItem = findItemByType(FITTING_TYPE.E);
    const icon = equipmentItem?.icon as string;
    const detail: FittingEntity = {
      category: CATALOGUE_TYPE.UN,
      system: '',
      entityType: FITTING_TYPE.E,
      itemType: FITTING_TYPE.E,
      icon,
      name: eqmtName,
      subtype: eqmtSubtype,
      s_number: sNumber,
      identifier, // @2024/12/10
    };

    return detail;
  };

  const applyNewEquipmentHandler = () => {
    if (!eqmtName || !eqmtSubtype) return;

    const detail = createFeatureEntity();
    // lazy notification to map to add new element!
    setTimeout(() => {
      const event = new CustomEvent(TKEvent.NEWELEMENT, { detail });
      document.dispatchEvent(event);
    }, 200);

    onClose();
  };

  // lazy close, and dispatch cancel tool event...
  const cancelNewElementHandler = () => {
    onClose();
    const evt = new Event(TKEvent.CANCEL_DRAWING_TOOL);
    document.dispatchEvent(evt);
  };

  return {
    eqmtName,
    setEqmtName,
    eqmtSubtype,
    setEqmtSubtype,
    sNumber,
    setSNumber,
    identifier,
    setIdentifier,
    applyNewEquipmentHandler,
    cancelNewElementHandler,
  };
};
