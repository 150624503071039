import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, EyeIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

import { Commander } from '../bridge/commander';
import { SubGroup } from '../types';

type SubGroupProps = {
  dimensionFields: string[];
  group: SubGroup;
  closeHandler: () => void;
};

type SubRow = {
  id: string;
  values: any[];
};

export const ExpandableSymbolGroup = ({
  dimensionFields,
  group,
  closeHandler,
}: SubGroupProps) => {
  // sub rows
  const rowsForDisplay: SubRow[] = group.rows.map((smb) => {
    const values = [`#${smb.index}`, 1];
    const dimension = smb.detail || {};
    dimensionFields.forEach((f) => {
      // FIXME: round the float value @2023/05/31
      const dimensionValue = Math.round(parseFloat(dimension[f]));
      const validValue = isNaN(dimensionValue) ? '' : dimensionValue;
      values.push(validValue);
    });
    values.push('supply'); // system
    return {
      id: smb.id || '',
      values,
    };
  });

  const viewInMapHandler = (id: string) => {
    closeHandler();
    Commander.doFlyToFeature(id);
  };

  return (
    <Disclosure as="div">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={clsx(
              'w-full flex text-center border-b border-gray-300 focus:outline-none hover:bg-blue-50',
              open ? 'bg-blue-50 font-semibold' : 'bg-white',
            )}
          >
            {group.summary.map((f, i) => (
              <span
                key={i}
                className={clsx(
                  'inline-block p-1 border-r border-gray-300 text-xs overflow-hidden h-6',
                  i == 0 ? 'w-36' : 'w-20',
                )}
              >
                {f}
                {!i && (
                  <ChevronUpIcon
                    className={clsx(
                      'size-4 text-gray-500 inline-block',
                      open ? '' : 'rotate-180',
                    )}
                  />
                )}
              </span>
            ))}
          </Disclosure.Button>
          <Disclosure.Panel className="w-full text-sm text-gray-600 bg-gray-50">
            {rowsForDisplay.map((row) => (
              <div
                key={row.id}
                className="border-b border-gray-300 flex text-center"
              >
                {row.values.map((v, i) => (
                  <span
                    key={i}
                    className={clsx(
                      'inline-block p-1 border-r border-gray-300 text-xs overflow-x-hidden',
                      i == 0 ? 'w-36' : 'w-20',
                    )}
                  >
                    {v}
                  </span>
                ))}
                <button
                  type="button"
                  title="View this symbol in Map"
                  className="inline-block py-1 px-2 text-xs focus:outline-none hover:text-blue-600"
                  onClick={() => viewInMapHandler(row.id)}
                >
                  <EyeIcon className="inline-block size-4 mr-1 hover:scale-125" />
                </button>
              </div>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
