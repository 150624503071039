import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { forwardRef } from 'react';
import {
  CheckboxFormatterProps,
  SortColumn,
  FormatterProps,
} from 'react-data-grid';
import { Link } from 'react-router-dom';

import { columnsForFileTable } from '@/config/model';

import { getComparator } from '../hooks/useDataGrid';
import { TKProjectRow } from '../types';

export const columnsWithAction = [
  ...columnsForFileTable,
  {
    key: 'actions',
    name: 'Action',
    sortable: false,
    resizable: true,
    minWidth: 70,
    width: 50,
    // eslint-disable-next-line
    formatter: (props: FormatterProps<TKProjectRow>) => {
      if (props.isCellSelected) {
        // console.log(props.row);
      }
      return (
        <Link
          className="focus:outline-none inline-block size-12 p-3"
          to={`/project/${props.row.projectId}`}
        >
          <ChevronRightIcon className="size-5" />
        </Link>
      );
    },
  },
];

export const CheckboxFormatter = forwardRef<
  HTMLInputElement,
  CheckboxFormatterProps
>(function CheckboxFormatter({ disabled, onChange, ...props }, ref) {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(e.target.checked, (e.nativeEvent as MouseEvent).shiftKey);
  }

  return <input type="checkbox" ref={ref} {...props} onChange={handleChange} />;
});

export const sortedRowsCallback = (
  sortColumns: readonly SortColumn[],
  rows: TKProjectRow[],
) => {
  if (sortColumns.length === 0) return rows;

  return [...rows].sort((a, b) => {
    for (const sort of sortColumns) {
      const comparator = getComparator(sort.columnKey);
      const compResult = comparator(a, b);
      if (compResult !== 0) {
        return sort.direction === 'ASC' ? compResult : -compResult;
      }
    }
    return 0;
  });
};
