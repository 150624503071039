import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

import { AnimateModal, DeleteModalProps } from '@/components/ui/modal';

/** Delete Multipal Pages Confirm */
export const DiscardConfirmModal = ({
  isOpen,
  isOnStage,
  action,
  lazyCloseHandler,
  discardMessage,
  deletesHandler,
}: DeleteModalProps) => {
  const isDeleteMode = action === 'Delete';

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={`${action || 'Delete'} Confirmation`}
    >
      <div
        className={clsx('justify-center flex', isDeleteMode ? 'py-0' : 'py-4')}
      >
        <ExclamationTriangleIcon
          className={clsx('size-12 text-red-600', isDeleteMode ? '' : 'hidden')}
          aria-hidden="true"
        />
      </div>
      {/* text content */}
      <p className="text-sm py-4">
        {discardMessage || `Are you serious to do this?`}
      </p>
      {/* button row */}
      <div className="flex gap-x-10 justify-between mx-0 mt-6">
        {/* close modal */}
        <button
          className="btn-red-border color-red font-semibold flex-1 red-shadow"
          onClick={() => lazyCloseHandler()}
        >
          Cancel
        </button>
        {/* save button */}
        <button
          className={clsx(
            'btn-green flex-1 leading-5 text-base bg-green shadow-md',
          )}
          onClick={() => {
            deletesHandler && deletesHandler();
            lazyCloseHandler();
          }}
        >
          Confirm
        </button>
      </div>
    </AnimateModal>
  );
};
