import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { API_URL, API_CTX, NETWORK_TIMETOUT_LIMIT } from '@/config';
import storage from '@/utils/storage';

import { authResponseFailureInterceptor } from './interceptor';

/**
 * parse error object to { code, message }.
 * @param error axios error response or native error object
 * @returns
 */
export const errorResponseToInfo = (error: any) => {
  const code = error.response?.status as number;
  const errorDetail = error.response?.data?.detail; // this is what our system return
  const errorMessage = error.response?.data?.message; // or use message property
  const defaultError = error.message;
  const message = errorDetail || errorMessage || defaultError;
  return { code, message };
};

/**
 * NOTE: this is legacy usage for original `bulletproof` demo feature(module)s use!
 * DO NOT USE this in taksoai-tool!
 *
 * @date 2024/03/06
 */
export const axios = Axios.create({
  baseURL: API_URL + '/v1/',
});

export const axiosEasy = Axios.create({
  baseURL: API_URL, // set domain only!
  headers: { 'Content-Type': 'application/json' },
  timeout: NETWORK_TIMETOUT_LIMIT,
});

export const downloadFile = (url: string) => {
  return axios.get(url, {
    responseType: 'blob', // important
  });
};

/**
 * HTTP Client regardless the backend environment
 *
 * @date 2024/03/06
 * @returns
 */
export const getClientWithCtx = () => {
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.baseURL = API_URL + API_CTX;
  return axios;
};

/**
 * General method to get axios instance for `project` module, aka `TaksoAI - tool`
 *
 * @returns axios client
 */
export const resetBaseURL = () => {
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.baseURL = API_URL;
  axios.defaults.timeout = NETWORK_TIMETOUT_LIMIT; // @2024/04/05 to dectect item catalouge loading failure
  return axios;
};

/**
 * Commonly used client in user related pages...
 * @returns
 */
export const restoreURL = () => {
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.baseURL = API_URL;
  return axios;
};

export const uploadUserURL = () => {
  axios.defaults.headers['Content-Type'] = 'multipart/form-data';
  axios.defaults.baseURL = API_URL;
  return axios;
};

/**
 * Upload blueprint pdf file
 *
 * @returns axios client
 */
export const uploadURL = () => {
  axios.defaults.headers['Content-Type'] = 'multipart/form-data';
  axios.defaults.baseURL = API_URL;
  return axios;
};

/**
 * Upload large pdf file with binary body
 */
export const uploadBinary = () => {
  axios.defaults.headers['Content-Type'] = 'application/pdf';
  axios.defaults.baseURL = API_URL;
  return axios;
};

/**
 * FIXME: do not add `authorization` header any more, it not allowed from gateway!
 * @date 2024/07/09
 * @param config
 * @returns
 */
function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  if (token) {
    config.headers['x-access-token'] = token;
  }
  // checking content-type from each request
  const contentType = config.headers['Content-Type'];
  if (!contentType) {
    config.headers['Content-Type'] = 'application/json';
  }
  config.headers.Accept = 'application/json';
  return config;
}

function authResponseSuccessInterceptor(response: AxiosResponse) {
  return response.data; // return backend created struct: APIResponse
}

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  authResponseSuccessInterceptor,
  authResponseFailureInterceptor,
);

axiosEasy.interceptors.request.use(authRequestInterceptor);
axiosEasy.interceptors.response.use(
  authResponseSuccessInterceptor,
  authResponseFailureInterceptor,
);
