import { useSyncExternalStore } from 'react';

import { FittingDimension, FITTING_DUCT as DUCT } from '../types';

/**
 * Handling dimension cache during the symbol addtion and corrections
 */
type FDCONFIG = { [key: string]: FittingDimension };

const defaultValues = {
  angle: '90',
  shape: 'round',
};

/**
 * TODO: add default
 * Dimension cache for user added symbols on drawing
 * @2024/11/26
 */
let initialDimension: FDCONFIG = {
  // runtime to add it...
  [DUCT.BALANCING_DAMPER]: defaultValues,
  [DUCT.ELBOW]: defaultValues,
  [DUCT.CENTER_LINE_REDUCER]: defaultValues,
  [DUCT.ECCENTRIC_REDUCER]: defaultValues,
  [DUCT.Y_JUNCTION]: defaultValues,
};

/**
 * callback functions would dynamically change
 */
let listeners: (() => void)[] = [];

const emitChange = () => {
  for (const listener of listeners) {
    listener();
  }
};

/**
 * Global dimension operations for symbol addition
 * @date 2024/11/26
 */
export const dimensionStore = {
  /**
   * cache fitting details
   * @param type fitting type
   * @param dimension fitting details
   */
  setDimensionBy(type: string, dimension: FittingDimension) {
    initialDimension = {
      ...initialDimension,
      [type]: dimension,
    };
    emitChange();
  },
  // == inline method ==
  subscribe(listener: () => void) {
    listeners = [...listeners, listener];
    return () => {
      // remove the newly added listener to achieve unsubscribe
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  /**
   * exposed state change handler for `useSyncExternalStore`
   * @returns an immutable catalogues store state
   */
  getSnapshot() {
    return initialDimension;
  },
};

/**
 * Check last used dimesion state
 * @returns dimension state in use
 */
export const useDimensionStore = () => {
  const { subscribe, getSnapshot } = dimensionStore;
  const dimensionConfig = useSyncExternalStore(subscribe, getSnapshot);

  const getDimensionBy = (itemType: string) => dimensionConfig[itemType] || {};

  return {
    ...dimensionConfig,
    getDimensionBy,
  };
};
