/**
 * Customizable and show more systems
 * @date 2024/04/25
 */
import CreatableSelect from 'react-select/creatable';

import { creatableSelectStyle } from '@/config/model';

import { useSystemsBy } from '../hooks/useSystemsBy';
import { TKSystem, FITTING_TYPE } from '../types';

type CreateMoreSystemsProp = {
  category: FITTING_TYPE;
  itemType: string;
  systems: TKSystem[];
  currentSystem: string;
  currentSystemChangeHandler: (newSystem: string) => void;
  /**
   * Not always necessary to have
   * @param isRawSelect
   * @returns
   */
  onMoreSystemSwitch: (isRawSelect: boolean) => void;
};

export const CreateMoreSystems = ({
  systems,
  category,
  itemType,
  currentSystem,
  currentSystemChangeHandler,
  onMoreSystemSwitch,
}: CreateMoreSystemsProp) => {
  const {
    isNormalView,
    mergedSystems,
    systemsByCategory,
    customSystems,
    ctmSystem,
    customSystemChangeHandler,
    customSystemCreatedHandler,
    normalView,
    abNormalView,
    selectSystemBy,
    closeSystemView,
  } = useSystemsBy(
    // === systems hook ===
    systems,
    category,
    currentSystemChangeHandler,
    itemType,
    onMoreSystemSwitch,
  );

  return (
    <>
      {/* normal select system */}
      <label className={normalView('color-primary text-sm block mt-4 mb-0')}>
        SYSTEM
        <select
          name="system"
          className="block w-full px-2 py-1 mt-1 text-sm frame-input"
          value={currentSystem}
          onChange={(event) => selectSystemBy(event.target.value)}
        >
          <option value="" disabled>
            Select system
          </option>
          {mergedSystems.map((ds) => (
            <option key={ds.short} value={ds.short}>
              {ds.fullName}
            </option>
          ))}
        </select>
      </label>
      {/* More systems panel after view switched */}
      <div
        className={abNormalView(
          'double-column-select bg-white border border-gray-300 rounded-lg overflow-y-scroll mt-2',
        )}
      >
        {systemsByCategory.map((system) => (
          <button
            key={system.short}
            type="button"
            className="system-select-item"
            onClick={() => closeSystemView(system.short)}
          >
            <span className="text-xs inline-block pb-1">
              {system.fullName} ({system.short})
            </span>
          </button>
        ))}
      </div>
      {/* MUST put select component into fragment & out of extend list - @2024/04/23 */}
      <div className="mt-3">
        {!isNormalView && (
          <CreatableSelect
            isClearable
            placeholder="Add Custom System..."
            onChange={customSystemChangeHandler}
            onCreateOption={customSystemCreatedHandler}
            options={customSystems}
            value={ctmSystem}
            styles={creatableSelectStyle}
            formatCreateLabel={(inputValue: string) =>
              `Create New System: ${inputValue}`
            }
          />
        )}
      </div>
    </>
  );
};
