/**
 * Setting drawing correction with brush
 * @2023/11/14
 *
 * @log add cache to rememver last setting
 */

import { useState, ChangeEvent } from 'react';

import {
  getBrushCorrectSetting as init,
  cacheBrushCorrectSetting as cache,
} from '../state';
import { TKEvent, TKSystem } from '../types';

export const useBrushCorrectSetting = (systems: TKSystem[]) => {
  const BCS = init();

  // symbol type: DUCT | PIPE
  const [symbolType, setSymbolType] = useState(BCS.symbolType);
  // selected system
  const [selectedSystem, setSelectedSystem] = useState<string>(
    BCS.selectedSystem,
  );
  // selected color
  const [renderColor, setRenderColor] = useState(BCS.renderColor);

  // cache new settings every rendering
  cache({
    symbolType,
    selectedSystem,
    renderColor,
  });

  const symbolTypeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSymbolType(event.target.value);
  };

  const systemChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const short = event.target.value;
    setSelectedSystem(short);
  };

  const colorTypeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRenderColor(event.target.value);
  };

  const applyBrushSelectHandler = () => {
    const detail = {
      symbolType,
      selectedSystem,
      renderColor,
    };
    document.dispatchEvent(
      new CustomEvent(TKEvent.APPLY_BRUSH_SETTING, { detail }),
    );
  };

  return {
    symbolType,
    selectedSystem,
    renderColor,
    symbolTypeChangeHandler,
    systemChangeHandler,
    colorTypeChangeHandler,
    applyBrushSelectHandler,
  };
};
