import * as React from 'react';
import { createRoot } from 'react-dom/client';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';
import App from './app';
import { initMonitoring } from './lib/sentry';

const params = new URLSearchParams(document.location.search);
const haveCRparam = params.get('canvasreplay'); // check if have the param
const disableCanvasReplay = haveCRparam == '0';
initMonitoring(false);

const root = document.getElementById('root');
if (!root) throw new Error('No root element found');

createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
