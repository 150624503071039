import centerLineReducerIcon from '@/assets/fittings/center_line_reducer.svg';
import elbowIcon from '@/assets/fittings/Elbow.svg';
import elbowPipingIcon from '@/assets/fittings/ELBOW_PIPING_icon.svg';
import tJunctionIcon from '@/assets/fittings/T_JUNCTION_icon.svg';
import helpIcon from '@/assets/icons/ic_outline-help.svg';
import widthIcon from '@/assets/icons/width_icon.svg';

export const missingIcons: { [key: string]: string } = {
  ['ELBOW_PIPING']: elbowPipingIcon,
  ['ELBOW_HVAC']: elbowIcon,
  ['SPIN_IN']: centerLineReducerIcon,
  ['T_JUNCTION']: tJunctionIcon,
};

export const HELP_ICON = helpIcon;
export const WIDTH_ICON = widthIcon;
