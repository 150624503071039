/**
 * A proud hooks took me 3 days to figure it out...
 * to BLOCK browser going back while meet some condition like not saved changes.
 *
 * @2023/05/03
 *
 * FIXME: Check page change state to decide if need no-saving popup!
 *
 * @2024/02/11
 */

import { useEffect } from 'react';

import { useSymbolsEditStore } from '../state';
import { TKEvent } from '../types';

/**
 * Force stay at current page if condition met
 *
 * @param changed flag to tell if need stay in current page
 * @param stepBackHandler hit the `Back` button of browser of change page url to parent page
 */
export const usePendingNavigation = (changed: boolean) => {
  const { getChangeBy } = useSymbolsEditStore();

  useEffect(() => {
    // === THIS IS A MUST TO BLOCK PAGE BACK FROM CHANGED DRAING ===
    // add one more state for history record, to allow user go back
    if (changed) {
      // NO need to add state - @2024/02/11
      history.pushState(null, document.title, location.href);
    }

    // decoupled notification from view, handle this event through: `useReviewEvents`
    const pendingBackHandler = () => {
      const customEvt = new Event(TKEvent.POPUPUNSAVINGMODAL);
      document.dispatchEvent(customEvt);
    };

    // console.log(`### project module initialized!`);
    const pendingHandler = function (event: PopStateEvent) {
      const { state } = event;
      // state availability check!
      if (!state || !state.usr) return; // null

      const { id } = state.usr;
      const changedByPage = getChangeBy(id);
      // restrict back navigation!
      if (changedByPage) {
        // NO need to add state - @2024/02/11
        history.pushState(null, document.title, location.href);
        pendingBackHandler();
      }
    };

    // popup the state pushed above!
    window.addEventListener('popstate', pendingHandler);
    // console.log(`>> listen browser popstate event ...`);

    return () => {
      // console.log(`### popstate listener removed after bach to homepage!`);
      // NOTE: TRICKY PART:
      // check last `changed` value if equal to current `changed` , if so, popup a null state history !
      if (changed) {
        // previously is `true`, but now is false, so popup the null state!
        history.back();
      }
      // and remove event listener
      window.removeEventListener('popstate', pendingHandler);
    };
  }, [changed, getChangeBy]);
};
