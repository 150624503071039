/**
 * Create a project payload to save
 *
 * FIXME: ADD USER NAME for `created_by`
 * @2024/03/12
 *
 * @param actid company id
 * @param name project name
 * @param userName logged in user name
 * @returns
 */

export const buildProjectPayload = (
  actid: string,
  name: string,
  project_number = '010101',
  userName: string,
) => ({
  id_account: actid,
  name,
  project_number,
  project_type: '',
  construction_sector: '',
  city: '',
  province: '',
  country: '',
  unit: 'metric',
  created_by: userName,
  last_modified_by: 'SA',
  status: '',
  favorite: 0,
});

/**
 * This is important in case of new user have no company assigned!
 */
export const mockCompanyID = '1';
