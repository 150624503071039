import { useEffect } from 'react';

import { tableScrolLazylHandler } from '../handlers/tableScrolLazylHandler';
import { CatalogueSubTotalGroup as CTG } from '../types';

/**
 * observe table container scrolling and make sticky group header
 *
 * @param subTotalGroups
 */
export const useTableScrollHandler = (subTotalGroups: CTG[], active = true) => {
  useEffect(() => {
    if (!subTotalGroups.length) return;
    const tableContainer = document.querySelector('.right-panel-table');
    if (!tableContainer) return;

    // observe groups position on scrolling...
    const onScrollHandler = tableScrolLazylHandler(
      tableContainer as HTMLElement,
    );
    const conditionalScroller = () => {
      active && onScrollHandler();
    };

    // LISTENING SCROLL EVENT...
    if (active) {
      console.log(`>>> listening scroll event!`);
      tableContainer.addEventListener('scroll', conditionalScroller);
    }

    return () => {
      // cleanup event listener ...
      tableContainer.removeEventListener('scroll', conditionalScroller);
      console.log(`## clear scroll event!`);
    };
  }, [subTotalGroups, active]);
};
