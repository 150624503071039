import { Suspense, ErrorInfo } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import { Spinner } from '@/components/ui/spinner';
import { useSymbolsEditStore, usePendingNavigation } from '@/features/project';
import { reportException, reportErrorWithMessage } from '@/lib/sentry';
import { useTokenErrorHandler } from '@/providers/useTokenErrorHandler';
import { log, error as printError } from '@/utils/logger';

/**
 * Call resetErrorBoundary() to reset the error boundary and retry the render.
 *
 * @returns
 */
const fallbackRender = ({ error }: FallbackProps) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
};

const logError = (error: Error, info: ErrorInfo) => {
  // Do something with the error, e.g. log to an external API
  reportException(error);

  log('## error message sent to sentry! ##');
  printError(error.message);

  if (!info.componentStack) return;
  reportErrorWithMessage(info.componentStack);
  log(info.componentStack);
};

/**
 * Page lazy loader & global error processer
 * @date 2024/10/30
 * @returns
 */
export const PageSafeWrapper = () => {
  const { changed } = useSymbolsEditStore();

  // FIXME: observe back navigation at project module, rather than `FileDetailsView`
  // ony in this way, the `popstate` can work perfectly!
  // @2023/05/05
  usePendingNavigation(changed);
  /**
   * Handle global token error from http client!
   * Enable this @2024/02/12
   */
  useTokenErrorHandler();

  /**
   * identify logged in user when location changed
   */
  // useLogRocketIdentify();

  return (
    <Suspense
      fallback={
        <div className="flex size-full items-center justify-center">
          <Spinner size="xl" />
        </div>
      }
    >
      {/* second level error boundary at protected routes */}
      {/* render child route elements */}
      <ErrorBoundary
        key={location.pathname}
        fallbackRender={fallbackRender}
        onError={logError}
      >
        <Outlet />
      </ErrorBoundary>
    </Suspense>
  );
};
