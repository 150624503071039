/**
 * build duct fields dynamically
 * @2023/02/16
 */

import { kebabCase } from '@/utils';

import { GO, isDimensionField } from '../types';

import { FittingFieldElement as FFE, InputRendererProps } from './form-inputs';

type DuctFieldsProps = {
  /** TODO: add unit suffix ... */
  suffix?: string;
  fields: string[];
  defaultValues?: GO;
  fieldRenderer: (fieldName: string) => InputRendererProps;
};

export const DuctDynaFields = ({
  fields,
  fieldRenderer,
  suffix,
  defaultValues,
}: DuctFieldsProps) => (
  <>
    {fields.map((fieldName, _, fields) => {
      const kebabField = kebabCase(fieldName);
      const InputByField = FFE[kebabField] || FFE['default'];
      const hasTwoWidth = fields.includes('width_2');
      const hasWidthAndWidth1 =
        fields.includes('width_1') && fields.includes('width');
      const isNumberInput = isDimensionField(kebabField);
      // check reset value, cos `defaultValues` could be undefined
      const resetFieldValue = { fieldValue: defaultValues?.[fieldName] || '' };
      return (
        <InputByField
          key={fieldName}
          oneWidthOnly={!hasTwoWidth && !hasWidthAndWidth1}
          {...fieldRenderer(fieldName)}
          {...resetFieldValue}
          suffix={isNumberInput ? suffix : ''}
          type={isNumberInput ? 'number' : 'text'}
        />
      );
    })}
  </>
);
