/**
 * Created at 2023/08/18
 */
import { zoomifyBaseURL } from '@/config';

import { filterCompletionFields, getReducedFieldsByShape } from './dimension';
import { TKItemCatalog, FittingMeta } from './entity';
import { missingIcons, HELP_ICON } from './icons';
import { BPSymbol } from './symbols';

/**
 * Convert item catalouge definition to item meta data
 * @param item Item catalogue definition
 * @returns FittingMeta(Pipe) data
 */
export const itemCatalogueToFittingMeta = (
  item: TKItemCatalog,
): FittingMeta => {
  const fieldsNoSystem = item.properties
    .map((prop) => prop.name)
    .filter((field) => field !== 'system');
  const hasShape = fieldsNoSystem.includes('shape');
  const fieldsNoShape = fieldsNoSystem.filter((field) => field !== 'shape');
  const fittingMeta: FittingMeta = {
    rawType: item.type, // @2024/07/02
    category: item.category,
    type: item.id,
    label: item.description,
    icon: item.s3_icon_path
      ? `${zoomifyBaseURL}${item.s3_icon_path}`
      : missingIcons[item.id] || HELP_ICON,
    fields: fieldsNoShape,
    props: item.properties.map((prop) => prop.name),
    hasSysem: !!item.properties.find((prop) => prop.name === 'system'),
    properties: item.properties,
  };
  if (hasShape) {
    fittingMeta.shape = ['Round', 'Square'];
  }
  return fittingMeta;
};

/**
 * Calculate completion percentage of a symbol in real time of symbol correction
 * FIXME: '0' input not a valid size to count for completion value
 * @2023/11/08
 *
 * @param symbol
 * @param fields dimension properties without 'system'
 * @returns
 */
export const completionCalculator = (
  symbol: BPSymbol,
  fields: string[],
): number => {
  const dimensions = symbol.detail;
  if (!dimensions) return 0;
  // FIXME: check no fields but `system` field case:
  // such as `TOILET`
  // @2024/12/06
  if (!fields.length && dimensions['system']) return 100;
  // check `shape`
  const { shape } = symbol.detail;
  if (fields.length == 0) return 0;
  const completionFields = filterCompletionFields(fields);
  // console.log(`>>> calculate completion with shape: ${shape}`);
  const reducedFields = getReducedFieldsByShape(completionFields, shape);
  // console.log(reducedFields);
  const eachShare = Math.floor(100 / (reducedFields.length + 1));
  const completion = [...reducedFields, 'system'].reduce(
    (total: number, field: string) => {
      const validInput = !!dimensions[field] && dimensions[field] !== '0';
      return validInput ? total + eachShare : total;
    },
    0,
  );
  // FIXME: sometimes, the whole cells filled, but the completion is 99
  // so, +1 is required to make it `completed`!
  // @2023/11/10
  if (completion >= 99) return 100;

  return Math.min(completion, 100);
};
