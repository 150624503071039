import { nanoid } from 'nanoid';
import { create } from 'zustand';

export type Notification = {
  id: string;
  type: 'info' | 'warning' | 'success' | 'error';
  title: string;
  message?: string;
};

type NotificationsStore = {
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, 'id'>) => void;
  dismissNotification: (id: string) => void;
  dropNotification: () => void;
  clearNotification: () => void;
};

/**
 * Toast notification and close after 5 secs
 *
 * @date 2024/06/25
 * @param note message
 */
export const autoCloseNotification = (note: Omit<Notification, 'id'>) => {
  useNotificationStore.getState().dropNotification();
  useNotificationStore.getState().addNotification(note);
  setTimeout(() => {
    useNotificationStore.getState().dropNotification();
  }, 3000);
};

export const useNotificationStore = create<NotificationsStore>((set) => ({
  notifications: [],
  addNotification: (notification) =>
    set((state) => ({
      notifications: [
        ...state.notifications,
        { id: nanoid(), ...notification },
      ],
    })),
  dismissNotification: (id) =>
    set((state) => ({
      notifications: state.notifications.filter(
        (notification) => notification.id !== id,
      ),
    })),
  dropNotification: () =>
    set((state) => ({
      notifications: state.notifications.slice(
        0,
        state.notifications.length - 1,
      ),
    })),
  clearNotification: () => set(() => ({ notifications: [] })),
}));

export const useNotifications = useNotificationStore;
