import clsx from 'clsx';
import { FC, ChangeEvent } from 'react';

import diameterIcon from '@/assets/icons/diameter_icon.svg';
import heightIcon from '@/assets/icons/height_icon.svg';
// import lengthIcon from '@/assets/icons/length_icon.svg';
import widthIcon from '@/assets/icons/width_icon.svg';
import { kebabCase } from '@/utils';

export type RadioButtonProps = {
  label?: string;
  value: string;
  name: string;
  selectedValue: string;
  selectChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const ImageRaidoButton = ({
  label = '',
  value,
  name,
  selectedValue,
  selectChangeHandler,
}: RadioButtonProps) => (
  <label className="label-block flex-1 color-primary">
    {label}
    <input
      type="radio"
      name={name}
      value={value}
      checked={selectedValue === value}
      onChange={selectChangeHandler}
    />
    <span className={`checkmark ${value}`}></span>
  </label>
);

const DiameterInput = ({
  diameter = '', // need default value
  fieldHandler = () => null,
}: {
  diameter: string;
  fieldHandler: (key: string, value: string) => void;
}) => (
  <label className="flex-1 color-primary py-2">
    <img src={diameterIcon} alt="diameter-icon" className="inline-block" />
    <input
      type="number"
      name="diameter"
      placeholder="Diameter in mm"
      className="inline-block frame-input prefix-icon ml-3 text-sm"
      value={diameter}
      onChange={(event) => fieldHandler('diameter', event.target.value)}
    />
  </label>
);

const HeightInput = ({
  height = '',
  fieldHandler = () => null,
}: {
  height: string;
  fieldHandler: (key: string, value: string) => void;
}) => (
  <label className="w-full color-primary py-2" title="Height">
    <img src={heightIcon} alt="height-icon" className="inline-block size-5" />
    <input
      type="number"
      name="height"
      placeholder="mm"
      className="inline-block frame-input prefix-icon ml-2 text-sm"
      value={height}
      onChange={(event) => fieldHandler('height', event.target.value)}
    />
  </label>
);

const WidthInput = ({
  widthName = '', // need a default value for kebabCase
  widthValue = '',
  oneWidthOnly, // one width no need label, use icon instead
  fieldHandler = () => null,
}: {
  widthName: string;
  widthValue: string;
  oneWidthOnly?: boolean;
  fieldHandler: (key: string, value: string) => void;
}) => (
  <label className="flex-1 color-primary text-sm py-2" title={widthName}>
    {widthName === 'Width' ? (
      <img src={widthIcon} alt="height-icon" className="inline-block size-5" />
    ) : (
      <span className="inline-block w-16">{widthName}</span>
    )}
    <input
      type="number"
      name={kebabCase(widthName)}
      placeholder="mm"
      className={clsx(
        'inline-block frame-input ml-2 text-sm',
        oneWidthOnly ? 'prefix-icon' : 'prefix-label',
      )}
      value={widthValue}
      onChange={(event) =>
        fieldHandler(kebabCase(widthName), event.target.value)
      }
    />
  </label>
);

export type InputRendererProps = {
  placeholder?: string;
  type?: string;
  suffix?: string;
  disabled?: boolean;
  fieldName: string;
  fieldValue: string;
  fieldLabel?: string; // added at 2023/07/12
  /** M | S */
  sizeVariant?: string; // added at 2024/04/24
  fieldHandler: (key: string, value: string) => void;
  blurHandler: (key: string, value: string) => void; // added at 2023/11/08
};

/**
 * commonly used field in current symbol edit dialog
 * @returns
 */
export const DefaultInput = ({
  disabled = false,
  fieldName = '',
  fieldLabel = '',
  fieldValue = '',
  suffix = '',
  type = 'text',
  placeholder = '',
  sizeVariant = 'S',
  fieldHandler = () => null,
  blurHandler = () => null,
}: InputRendererProps) => (
  <label
    className="w-full color-primary text-sm py-2 relative"
    data-input="default"
  >
    {fieldLabel || fieldName.toUpperCase()}
    <input
      type={type}
      step=".01"
      name={kebabCase(fieldName)}
      placeholder={placeholder}
      className={clsx(
        'inline-block frame-input text-sm',
        sizeVariant == 'S' ? 'prefix-label-unknow' : '',
      )}
      value={fieldValue}
      disabled={disabled}
      onChange={(event) =>
        fieldHandler(kebabCase(fieldName), event.target.value)
      }
      onBlur={(event) => blurHandler(fieldName, event.target.value)}
    />
    {/* FIXME: suffix could be empty, will create a gray area */}
    {suffix && <span className="input-unit top-7 frame-suffix">{suffix}</span>}
  </label>
);

export const DropdownSelect = ({
  fieldName = '',
  fieldLabel = '',
  fieldValue = '',
  options = [''],
  fieldHandler,
  labelClass = 'prefix-label',
}: {
  fieldName: string;
  fieldLabel?: string;
  fieldValue: string;
  options: string[];
  labelClass?: string;
  fieldHandler: (key: string, value: string) => void;
}) => (
  <label className="flex-1 color-primary py-2">
    {fieldLabel || fieldName}
    <select
      name={kebabCase(fieldName)}
      className={clsx('frame-input-select ml-2 text-sm', labelClass)}
      value={fieldValue}
      onChange={(event) =>
        fieldHandler(kebabCase(fieldName), event.target.value)
      }
    >
      {options.map((opt) => (
        <option key={opt} value={opt}>
          {opt}
        </option>
      ))}
    </select>
  </label>
);

export const FittingFieldElement: { [key: string]: FC<any> } = {
  angle: (props) => (
    <DropdownSelect
      fieldName="Angle"
      fieldValue={props['angle']}
      fieldHandler={props.fieldHandler}
      options={['90', '60', '45', '30']}
    />
  ),
  d1: (props) => (
    <WidthInput
      widthName="D1"
      widthValue={props['d1']}
      fieldHandler={props.fieldHandler}
    />
  ),
  d2: (props) => (
    <WidthInput
      widthName="D2"
      widthValue={props['d2']}
      fieldHandler={props.fieldHandler}
    />
  ),
  d3: (props) => (
    <WidthInput
      widthName="D3"
      widthValue={props['d3']}
      fieldHandler={props.fieldHandler}
    />
  ),
  width: (props) => (
    <WidthInput
      widthName="Width"
      widthValue={props['width']}
      fieldHandler={props.fieldHandler}
    />
  ),
  w3: (props) => (
    <WidthInput
      widthName="W3"
      widthValue={props['w3']}
      fieldHandler={props.fieldHandler}
    />
  ),
  diameter: DiameterInput,
  height: HeightInput,
  default: DefaultInput,
};
