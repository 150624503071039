/**
 * Manage systems view switching in cooperation to `CreateMoreSystems`
 *
 * @date 2024/04/25
 */

import clsx from 'clsx';
import { useState } from 'react';

export const useNormalView = () => {
  const [isNormalView, setIsNormalView] = useState(true);
  const normalView = (classes: string) => {
    return clsx(classes, { hidden: !isNormalView });
  };

  return {
    isNormalView,
    normalView,
    setIsNormalView,
  };
};
