import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as React from 'react';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';

import { Notifications } from '@/components/ui/notifications';
import { Spinner } from '@/components/ui/spinner';
import { AuthLoader } from '@/lib/auth';
import { queryConfig } from '@/lib/react-query';
import { reportException } from '@/lib/sentry';

import { ErrorFallback } from './errorFallback';

type AppProviderProps = {
  children: React.ReactNode;
};

const logErrorToService = (error: Error) => {
  reportException(error);
};

/**
 * Big change in v2 here:
 * No `BrowserRouter` component in this AppProvider, move it to the `AppRouter` from `app.tsx`
 * @date 2024/06/04
 * @returns
 */
export const AppProvider = ({ children }: AppProviderProps) => {
  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: queryConfig,
    });
  });
  return (
    <React.Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center">
          <Spinner size="xl" />
        </div>
      }
    >
      {/* first level error boundary at global scale */}
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={logErrorToService}
      >
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {import.meta.env.DEV && <ReactQueryDevtools />}
            <Notifications />
            <AuthLoader
              renderLoading={() => (
                <div className="flex h-screen w-screen items-center justify-center">
                  <Spinner size="xl" />
                </div>
              )}
            >
              {children}
            </AuthLoader>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
      {/* add toast container to global level - @2024/06/11 */}
      <ToastContainer theme="dark" autoClose={3000} limit={1} />
    </React.Suspense>
  );
};
