import { CSSProperties } from 'react';
import { SelectColumn } from 'react-data-grid';

import { SLIDE_SHOW_COUNTS } from '.';

// *** UTILITIES FOR COMPONENTS ***

/**
 * React-select input style
 * @date 2024/03/11
 */
export const selectCustomStyle = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderColor: state.isFocused ? 'grey' : '#B5C2DC',
    borderRadius: 8,
  }),
  option: (baseStyles: any) => ({
    ...baseStyles,
    borderBottom: '1px solid #EEEEEE',
  }),
};

export const creatableSelectStyle = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderColor: state.isFocused ? 'grey' : '#B5C2DC',
    borderRadius: 8,
    fontSize: 12,
  }),
  option: (baseStyles: any) => ({
    ...baseStyles,
    fontSize: 12,
    borderBottom: '1px solid #EEEEEE',
  }),
};

export const spinnerStyle: CSSProperties = {
  display: 'inline-block',
  margin: '0 auto',
};

export const settingsForSlick = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: SLIDE_SHOW_COUNTS,
  // FIXME: change from 2 to 4 in order to fix issue #324
  // https://taksoai.atlassian.net/browse/TAK-324
  slidesToScroll: SLIDE_SHOW_COUNTS,
};

export const columnsForFileTable = [
  {
    ...SelectColumn,
    headerCellClass: 'tk-checkbox-in-cell',
    cellClass: 'tk-checkbox-in-cell',
  },
  // { key: 'id', name: 'ID' },
  { key: 'projectName', name: 'Project' },
  { key: 'fileName', name: 'File name' },
  { key: 'state', name: 'State', width: 80, minWidth: 60 },
  { key: 'fileAmount', name: 'Pages', width: 70, minWidth: 60 },
  { key: 'location', name: 'Location' },
  { key: 'lastModified', name: 'Date modified' },
  { key: 'creator', name: 'Creator' },
];
