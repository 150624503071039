import { getThrottleFunction } from '@/utils';

/**
 * Making sticky symbol group header
 *
 * @param tableContainer table on the summary modal
 * @returns
 */
export const tableScrolLazylHandler = (tableContainer: HTMLElement) => {
  /**
   * NOTE: The `scrollDirection` must be put outside of throttled function
   */
  const scrollDirection = { lastScroll: 0, direction: 1 };
  // scrolling parameters
  const snapToTopThreshold = 100;
  const selectors = ['sticky-group-header', 'fixed-group-header'];
  const fixedSelector = `.${selectors[0]}.${selectors[1]}`;

  /**
   * create a new throttled fuction for `scroll` event handling
   */
  return getThrottleFunction(() => {
    /**
     * table rectangle checking must put inside the handler
     * to get the acurate positiion after popup animation
     */
    const tableRect = tableContainer.getBoundingClientRect();
    const tableScrollTop = tableContainer.scrollTop;
    // ==== STEP 1: CHECK DIRECTION ===
    if (scrollDirection.lastScroll < tableScrollTop) {
      // console.log(`===> scroll down!`);
      scrollDirection.direction = 1;
    } else {
      // console.log(`===> scroll up!`);
      scrollDirection.direction = -1;
    }
    // === STEP 2: HANDLING SCROLL DOWN for `subtotal-group` ===
    const groups = tableContainer.querySelectorAll('.subtotal-group');
    if (scrollDirection.direction === 1) {
      groups.forEach((group, i) => {
        const groupRect = group.getBoundingClientRect();
        // console.log(`>> group ${i + 1} postion: ${groupRect.top}`);
        const yAxisDiff = groupRect.top - tableRect.top;
        if (yAxisDiff < snapToTopThreshold) {
          const header = group.querySelector(`.${selectors[0]}`);
          // make the header snapping to top
          if (header) {
            // FIXME: use z-[?] to set z-index on the fly - @2024/07/10
            header.classList.add(selectors[1], `z-[${(i + 1) * 10}]`);
            header.setAttribute('style', `top: ${tableRect.top + 1}px`);
          }
          // add the conatiner top space to show the first row under sticky header
          const hasTopSpace = tableContainer.classList.contains('pt-20');
          !hasTopSpace && tableContainer.classList.add('pt-20');
        }
      });
    }
    // === STEP 3: HANDLING SCROLL UP for `.sticky-group-header` ===
    if (scrollDirection.direction === -1) {
      groups.forEach((group, i) => {
        const groupRect = group.getBoundingClientRect();
        const yAxisDiff = groupRect.top - tableRect.top;
        if (yAxisDiff > snapToTopThreshold / 3) {
          const fixedHeader = group.querySelector(fixedSelector);
          if (!fixedHeader) return;
          fixedHeader.classList.remove(selectors[1], `z-${(i + 1) * 10}`);
        }
      });
      if (tableScrollTop < snapToTopThreshold / 3) {
        tableContainer.classList.remove('pt-20');
      }
    }
    // === STEP 4: SAVE THE SCROLL POSITION ===
    // save the latest scroll position!
    scrollDirection.lastScroll = tableScrollTop;
  }, 200);
};
