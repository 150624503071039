/**
 * Caught backend returned errors
 */
export const AUTHERRORCODES = [400, 401, 403, 440];

export enum HTTPErrorEvent {
  /** 400 - Missing token in request */
  BADREQUEST = 'badRequest',
  /** 401 - Invalid or expired token, Invalid credentials (user or password) */
  UNAUTHORIZED = 'unauthorized',
  /** 403 - Illegal to access resources */
  FORBIDDEN = 'forbidden',
  /** 440 - Valid token but user’ session is expired */
  EXPIRED = 'expired',
}

/**
 * these two cases need to renew the token,
 * and need to match belowing `TOKEN_ERROR_EVENTS`
 */
export const TOKENINVALIDCODES = [400, 401, 440];

/**
 * - 400, bad request
 * - 440, expired
 * - 401, unauthorized
 */
export const TOKEN_ERROR_EVENTS = [
  HTTPErrorEvent.BADREQUEST,
  HTTPErrorEvent.EXPIRED,
  HTTPErrorEvent.UNAUTHORIZED,
];

export const HTTP_CODE: {
  [key: number]: {
    type: string;
    description: string;
    action: string;
    event: string;
  };
} = {
  400: {
    type: 'bad request',
    description: 'missing token in request',
    action: 'add token in headers of request',
    event: HTTPErrorEvent.BADREQUEST,
  },
  401: {
    type: 'unauthorized',
    description:
      'Invalid or expired token, Invalid credentials (user or password)',
    action: 'login to obtain a fresh token',
    event: HTTPErrorEvent.UNAUTHORIZED,
  },
  403: {
    type: 'forbidden',
    description: 'Valid token but restricted permission',
    action: 'validate user’s role(s)',
    event: HTTPErrorEvent.FORBIDDEN,
  },
  440: {
    type: 'session expired or time out',
    description: 'Valid token but user’ session is expired',
    action: 'login',
    event: HTTPErrorEvent.EXPIRED,
  },
};
