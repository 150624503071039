import * as _ from 'lodash-es';

// import acvIcon from '@/assets/fittings/ACV_v1.svg';
// import fireDamperIcon from '@/assets/fittings/Fire_Damper.svg';
import helpIcon from '@/assets/icons/ic_outline-help.svg';

import {
  LENGTH_FIELD,
  LENGTH_STR_FIELD,
  checkDuctRoundOnly,
} from './dimension';
import { FittingMeta } from './entity';
import {
  CATALOGUE_TYPE,
  FITTING_TYPE as FIT,
  PAGE_UNIT,
  DimensionSuffix as SFX,
  LENGTH_TYPE as LEN,
  SymbolType,
  SPECIAL_JOB_ITEM as SJI,
} from './options';

export const getInputSuffixBy = (unit: string | undefined) =>
  unit === PAGE_UNIT.I ? SFX.I : SFX.MM;

export const unit2Suffix = getInputSuffixBy;

export const isFittingSymbol = (category: string) => {
  return category === FIT.D || category === FIT.P;
};

export const isLengthSymbol = (category: string) => {
  return category === LEN.P || category === LEN.D;
};

/**
 * Revised equipment checker using both `category` and `itemType`
 * @date 2024/05/08
 * @param category
 * @param itemType
 * @returns
 */
export const isEquipment = (category: string, itemType: string) => {
  return category === FIT.E || itemType === SymbolType.EQUIPMENT;
};

/**
 * TODO: move to `useCatalogueStore`
 * Find out common fields of different fittings
 *
 * TWO cases for this function:
 *
 * - `useExpendComnFields` hook doese not need str length field.
 * - `getCommonFields4Table` to figure out the columns of table, it does need str length field.
 *
 * So, add one more optional parameter `ignoreLength`, true by default, but false in the 2nd case.
 *
 * @date 2024/03/14
 *
 * @param types entity types
 * @param ignoreLength if need length to display
 */
export const getCommonFieldsOfFittings = (
  types: string[],
  ignoreLength = true,
) => {
  // Validate step : null, may need other filter...
  const validTypes = types.filter((type) => !!type);

  const allFieldsByType: string[][] = [];
  const allFieldsInSet = new Set<string>();
  const checkExistanceInAll = (field: string) => {
    let existing = true;
    allFieldsByType.forEach((fields) => {
      if (!fields.includes(field)) return (existing = false);
    });
    return existing;
  };
  // step 1: get all the fields of all the types
  validTypes.forEach((type) => {
    // TODO: use the function in store:
    const fields = findPropsByType(type);
    // console.log(fields);
    fields.forEach((f) => allFieldsInSet.add(f));
    allFieldsByType.push(fields);
  });
  // step 2: find the common fields across all the types
  const commonFields: string[] = [];
  allFieldsInSet.forEach((f) => {
    const isCommon = checkExistanceInAll(f);
    isCommon && commonFields.push(f);
  });

  // FIXME: remove `length_1` & `length_unit_str` field if its in common fields
  // @2024/02/21
  const lengthFieldPos = commonFields.indexOf(LENGTH_FIELD);
  if (lengthFieldPos != -1) {
    commonFields.splice(lengthFieldPos, 1);
  }
  const lengthStrFieldPos = commonFields.indexOf(LENGTH_STR_FIELD);
  if (lengthStrFieldPos != -1 && ignoreLength) {
    commonFields.splice(lengthStrFieldPos, 1);
  }

  // FIXME: add `system` as default common field
  // @2023/08/30
  if (!commonFields.includes('system')) {
    return commonFields.concat('system');
  }
  return commonFields;
};

// ========================= OBSOLETE FUNCTIONS =================

/**
 * TODO: implement this with `zustand` store!
 * need a global catalogues cache for all, to fetch uncertain type
 * @date 2023/05/18
 * @deprecated to abandon in the coming weeks...
 */
export const ALL_DEFINED_CATALOGUES: FittingMeta[] = [];

/**
 * === Common method to find fields of a specific item type ===
 *
 * NO `shape` & NO `system` in result!!
 * @deprecated to abandon in the coming weeks...
 * @param type item type
 * @returns
 */
export const findFieldsByType = (type: string) => {
  const item = ALL_DEFINED_CATALOGUES.find((item) => item.type === type);
  return item ? item.fields : [];
};

/**
 * @deprecated to abandon in the coming weeks...
 * @param type
 * @returns
 */
export const findIconByType = (type: string) => {
  const item = ALL_DEFINED_CATALOGUES.find((item) => item.type === type);
  return item ? item.icon : helpIcon;
};

/**
 * @deprecated to abandon in the coming weeks...
 * @param type
 * @returns
 */
export const findRawProperties = (type: string) => {
  const item = ALL_DEFINED_CATALOGUES.find((item) => item.type === type);
  return item ? item.properties : [];
};

/**
 * Get field definition of one item type
 * @deprecated to abandon in the coming weeks...
 * @param type item type
 * @param field field name
 * @returns
 */
export const findItemPropertyBy = (type: string, field: string) => {
  const item = ALL_DEFINED_CATALOGUES.find((item) => item.type === type);
  if (!item) return null;
  const property = item.properties.find((p) => p.name === field);
  if (!property) return null;
  return property;
};

/**
 * General function to get item properties defined in backend, `system` field not included!
 * @deprecated to abandon in the coming weeks...
 * @param type item id
 * @returns props fields list
 */
export const findPropsByType = (type: string) => {
  const item = ALL_DEFINED_CATALOGUES.find((item) => item.type === type);
  return item ? item.props : [];
};

/**
 * get item definition with type
 * @deprecated to abandon in the coming weeks...
 * @param type item id with capitalized format
 * @returns
 */
export const findItemByType = (type: string) => {
  return ALL_DEFINED_CATALOGUES.find((item) => item.type === type);
};

/**
 * Unified && flexible fields fetcher, for length symbol to add `length` field
 *
 * FIXME: is it right place to add length field here?
 * FIXME: to review all the parameters...if necessary - 2023/11/17
 *
 * USE CASES:
 * 1. jobItemTransformer/symbolDetailEnhancer
 * 2. FeatureProperties display
 * @deprecated to abandon in the coming weeks...
 * @param type item type
 * @param checkShape
 * @returns
 */
export const getSymbolFields = (type: string, checkShape?: boolean) => {
  if (type === LEN.P || type === LEN.D) {
    const fields = getLengthFields(type, checkShape);
    const noLength = fields.filter((f) => f !== 'length');
    // move length field at last postion!
    return [...noLength, 'length'];
  }

  // FIXME: simpler way to find fields - @2023/08/25
  return findFieldsByType(type);
};

/**
 * ***** SYSTEM LEVEL `FITTING` DEFINITIONS ******
 * **** dynamic filled by item api data *****
 * *** at `useItemCatalogue` ***
 * ******************************************
 * @deprecated to abandon in the coming weeks...
 */
export const FITTING_CATALOGUES: FittingMeta[] = [];

/**
 * get fitting types
 * @deprecated to abandon in the coming weeks...
 * @returns fitting items
 */
export const getDuctFittings = () => {
  if (!FITTING_CATALOGUES.length) return [];
  return FITTING_CATALOGUES.filter((item) => item.category === FIT.D);
};
/**
 * get pipe types
 * @deprecated to abandon in the coming weeks...
 * @returns pipe items
 */
export const getPipeFittings = () => {
  if (!FITTING_CATALOGUES.length) return [];
  const pfilter = (item: FittingMeta) => item.category === FIT.P;
  const pfits = FITTING_CATALOGUES.filter(pfilter);
  return pfits;
};

/**
 * Get the predefined fields needed for one type of symbols.
 * No shape, No system by default
 *
 * FIXME: any reason to use this specifically? - 2023/11/17
 *
 * USE CASES:
 *
 * 1. transformSymbol/circleSymbolToJobItem to save into backend
 *
 * @deprecated to abandon in the coming weeks...
 * @param type item type from job item.id
 * @returns
 */
export const getFittingFields = (
  type: string,
  checkSystem?: boolean,
  checkShape?: boolean,
) => {
  const fitting = FITTING_CATALOGUES.find((item) => item.type === type);
  const fields = fitting ? fitting.fields : [];
  const hasShape = fitting ? fitting.shape : false;
  if (!checkSystem) {
    if (checkShape && hasShape) {
      return ['shape', ...fields];
    }
    return fields;
  }
  const withSystem = fitting ? !!fitting.hasSysem : false;
  return withSystem ? [...fields, 'system'] : fields;
};

/**
 * ***** SYSTEM LEVEL `LENGTH` DEFINITIONS *****
 * **** dynamic filled by item api data *****
 * *** at `useItemCatalogue` ***
 * ******************************************
 * @deprecated to abandon in the coming weeks...
 */
export const LENGTH_CATALOGUES: FittingMeta[] = [];

/**
 * Use Cases:
 *
 * 1. transformSymbol/singlelineSymbolToJobItem
 * @deprecated
 * @param type
 * @param checkShape
 * @returns
 */
export const getLengthFields = (type: string, checkShape?: boolean) => {
  const fitting = LENGTH_CATALOGUES.find((item) => item.type === type);
  if (!fitting) return [];
  const fields = fitting.fields;
  return checkShape ? ['shape', ...fields] : fields;
};

/**
 * get shape existence
 *
 * FIXME: check if round only, then does not need shape select in new duct dialog
 * @date 2024/03/21
 * @deprecated
 * @param ductType duct type with snake case format
 * @returns
 */
export const shapeFinder = (ductType: string | undefined) => {
  if (!ductType) return false;
  const capitalize = ductType.toUpperCase();
  // thats rectangular, so need shape
  if (capitalize === SJI.RECT_DUCT) return true;

  // NO need to toggle shape select!
  const isRoundOnly = checkDuctRoundOnly(ductType);
  if (isRoundOnly) return false;

  const fitting = getDuctFittings().find((duct) => duct.type === capitalize);
  return fitting ? !!fitting.shape : false;
};

/**
 * duct fitting types in snake case
 * @deprecated
 * @returns set
 */
export const getDuctTypeSet = () => {
  const types = new Set<string>();
  getDuctFittings().forEach((d) => types.add(_.snakeCase(d.type)));
  return types;
};

/**
 * pipe fitting types in snake case
 * @deprecated
 * @returns set
 */
export const getPipeTypeSet = () => {
  const types = new Set<string>();
  getPipeFittings().forEach((p) => types.add(_.snakeCase(p.type)));
  return types;
};

/**
 * @deprecated
 * @returns
 */
export const getAllDuctCategoryItems = () => {
  return FITTING_CATALOGUES.filter((item) => item.category === FIT.D);
};

/**
 * @deprecated
 */
export const getAllPipeCategoryItems = () => {
  FITTING_CATALOGUES.filter((item) => item.category === FIT.P);
};

/**
 * @deprecated
 * @param type
 * @returns
 */
export const getLengthProps = (type: string) => {
  const fitting = LENGTH_CATALOGUES.find((item) => item.type === type);
  return fitting ? fitting.props : [];
};

/**
 * @deprecated
 * @returns
 */
export const getDuctLength = () => {
  return LENGTH_CATALOGUES.filter((item) => item.category === 'DUCT');
};

/**
 * @deprecated
 * @returns
 */
export const getPipeLength = () => {
  return LENGTH_CATALOGUES.filter((item) => item.category === 'PIPE');
};

/**
 * @deprecated
 * get fields from meta data
 * TODO: find pipes fields..
 *
 * @param ductType duct type with snake case format
 * @returns
 */
export const ductFieldsFinder = (ductType: string | undefined) => {
  if (!ductType) return [];
  const capitalize = ductType.toUpperCase();
  const fitting = getDuctFittings().find((duct) => duct.type === capitalize);
  console.log(fitting);
  return fitting ? fitting.fields : [];
};

/**
 * Obsolete function
 *
 * @deprecated in favor of `getPropsByType` - 2023/05/26
 * @param type
 * @returns
 */
export const getFittingProps = (type: string) => {
  const fitting = FITTING_CATALOGUES.find((item) => item.type === type);
  return fitting ? fitting.props : [];
};

/**
 * decide what type of correction modal to open
 *
 * @deprecated 2023/12/11
 *
 * @param type symbol entity type
 *
 * @returns
 */
export const checkSymbolCategory = (type: string): string => {
  // console.log(`checking type: ${type}`);
  const ducts = getDuctTypeSet();
  if (ducts.has(_.snakeCase(type))) return FIT.D;

  const pipes = getPipeTypeSet();
  if (pipes.has(_.snakeCase(type))) return FIT.P;

  return CATALOGUE_TYPE.UN;
};

/**
 * @deprecated
 *
 * 18 type of DUCT fittings
 * @2022/12/13
 */
export enum FITTING_DUCT {
  BALANCING_DAMPER = 'BALANCING_DAMPER',
  MANUAL_BALANCING_DAMPER = 'MANUAL_BALANCING_DAMPER',
  CENTER_LINE_REDUCER = 'CENTER_LINE_REDUCER',
  ECCENTRIC_REDUCER = 'ECCENTRIC_REDUCER',
  ELBOW = 'ELBOW',
  EXHAUST_DUCT = 'EXHAUST_DUCT',
  FIRE_DAMPER = 'FIRE_DAMPER',
  METER_DUCT = 'METER_DUCT',
  MOTORIZED_DAMPER = 'MOTORIZED_DAMPER',
  MOTORIZED_COMBINATION_FIRE_AND_SMOKE_DAMPER = 'MOTORIZED_COMBINATION_FIRE_AND_SMOKE_DAMPER',
  RETURN_DUCT = 'RETURN_DUCT',
  RETURN_DUCT_DOWN = 'RETURN_DUCT_DOWN',
  ROUND_DUCT_DOWN = 'ROUND_DUCT_DOWN',
  ROUND_DUCT_UP = 'ROUND_DUCT_UP',
  SUPPLY_AIR_DIFFUSER = 'SUPPLY_AIR_DIFFUSER',
  SUPPLY_OR_OUTSIDE_AIR_DUCT = 'SUPPLY_OR_OUTSIDE_AIR_DUCT',
  PERIMETER_DIFFUSER = 'PERIMETER_DIFFUSER',
  Y_JUNCTION = 'Y_JUNCTION',
  STRAIGHT_LENGTH_DUCT = 'STRAIGHT_LENGTH_DUCT',
}

/**
 * @deprecated
 * 24 type of PIPE fittings
 * @2022/12/13
 */
export enum FITTING_PIPE {
  ACV = 'ACV',
  BALL_VALVE = 'BALL_VALVE',
  BACK_FLOW_PREVENTER = 'BACK_FLOW_PREVENTER',
  BUTTERFLY_VALVE = 'BUTTERFLY_VALVE',
  CAPPED_PIPE = 'CAPPED_PIPE',
  CBV = 'CBV',
  CHECK_VALVE = 'CHECK_VALVE',
  END_POINT_A = 'END_POINT_A',
  END_POINT_B = 'END_POINT_B',
  GATE_VALVE = 'GATE_VALVE',
  MVB_VALVE = 'MVB_VALVE',
  METER_PIPE = 'METER_PIPE',
  PIPE_UP = 'PIPE_UP',
  PUMP = 'PUMP',
  PRESSURE_GAUGE = 'PRESSURE_GAUGE',
  SOLENOID_VALVE = 'SOLENOID_VALVE',
  SHUT_OFF_VALVE = 'SHUT_OFF_VALVE',
  STRAINER = 'STRAINER',
  // STRAIGHT_LENGTH_DUCT = 'STRAIGHT_LENGTH_DUCT',
  SRV = 'SRV',
  PRV = 'PRV',
  THERMOMETER = 'THERMOMETER',
  UNION = 'UNION',
  V_AND_C = 'V_AND_C',
}

// ======================== Review panel category -> sub-types relation ==========
/**
 * @deprecated
 * @param category
 * @returns
 */
export const getItemTypesByCategory = (category: string): FittingMeta[] => {
  if (category === FIT.D) return getDuctFittings();
  if (category === FIT.P) return getPipeFittings();
  if (category === LEN.D) return getDuctLength();
  if (category === LEN.P) return getPipeLength();
  return [];
};
