/**
 * Dom event listeners
 * @date 2024/04/08
 */

import { Size } from 'ol/size';

import { KEY_CODES } from '@/types/options';
import { log, warn } from '@/utils/logger';

import { domContextMenuHandler } from '../handlers/domCxtMenuHandler';
import { BPMapEditorAPI } from '../map/BPMapAPI';
import {
  USER_VALIDATED_FIELD as UVF,
  GO,
  FittingEntity,
  FILTERS,
} from '../types';
import { BPSymbol } from '../types/symbols';

import * as relayer from './eventsRelayer';

export const CommandListener = {
  /** BPMapEditoer instance */
  _map: {},
  map: function () {
    return this._map as BPMapEditorAPI;
  },
  /** drawing symbols */
  _symbols: [{}],
  symbols: function () {
    return this._symbols as BPSymbol[];
  },
  /** if editor is locked */
  _locked: false,
  locked: function () {
    return this._locked;
  },
  /** drawing id */
  _pageId: '',
  pageId: function () {
    return this._pageId;
  },
  /** if side panel open */
  _reviewPanelOpen: false,
  isReviewPanelOpen: function () {
    return this._reviewPanelOpen;
  },
  /** symbols filter type cache */
  _symbolFilter: FILTERS.ALL as string,
  setSymbolFilter: function (filter: string) {
    this._symbolFilter = filter;
  },
  /** @deprecated NOT IN USE */
  symbolFilter: function () {
    return this._symbolFilter;
  },
  resetSymbolFilter: function () {
    this._symbolFilter = FILTERS.ALL;
  },
  /** review panel shouldn't be part of context */
  setReviewPanel: function (open: boolean) {
    this._reviewPanelOpen = open;
  },
  resetReviewPanel: function () {
    this._reviewPanelOpen = false;
  },
  /** =========== set context ==================== */
  setContext: function (
    map: BPMapEditorAPI,
    /** all the symbols of current drawing */
    symbols: BPSymbol[],
    pageId: string,
    locked: boolean,
  ) {
    this._map = map;
    this._symbols = symbols;
    this._locked = locked;
    this._pageId = pageId;
  },
  clearContext: function () {
    this._map = {};
    this._symbols = [];
    this._pageId = '';
    this._locked = false;
  },
  /** ===============EVENT HANDLERS ==================== */
  onWindowBlur: function (event: FocusEvent) {
    this.map().onCtrlKeyReleased(event);
  },
  onWindowFocus: function () {
    // console.log('has focus');
  },
  zoomInDrawingAfterTable: function () {
    setTimeout(() => this.map().zoomBy(4.2), 400);
  },
  delightSymbols: function (event: Event) {
    const { detail } = event as CustomEvent;
    const { system } = detail;
    // this.map().delightSymoblsBy(system);
  },
  hightlightSymbolsBySystem: function (event: Event) {
    const { detail } = event as CustomEvent;
    const { system, color } = detail;
    // this.map().hightlightSymbolsBy(system, color);
  },
  showFeaturesBy: function (event: Event) {
    const { detail } = event as CustomEvent;
    const symbols = detail as BPSymbol[];

    // get possible activated feature
    const activedFeature = this.map().activeFeature;
    // console.log(activedFeature);

    this.map().renderSimple(symbols);

    // no previous highlited feature
    if (!activedFeature) return;

    // active the feature again
    const fid = activedFeature.getId();
    this.map().focusFeature(fid);
  },
  filterFeaturesBy: function ({ detail: type }: CustomEvent) {
    this.map().setFilterType(type);
    this.map().renderSymbols(this.symbols(), true);
  },
  reverseTextDisplay: function ({ detail }: CustomEvent) {
    // if `hide size text` box checked
    this.map().inverseFeatureText(detail);
  },
  reverseFeatures: function ({ detail }: CustomEvent) {
    this.map().inverseFeatureSelection(detail);
  },
  onForceUpdateMap: function (event: Event) {
    this.map().resizeViewport();
  },
  onReCheckFeature: function ({ detail: featureId }: CustomEvent) {
    this.map().focusFeature(featureId);
  },
  onClearCheckFeature: function (event: Event) {
    this.map().deFocusFeature();
  },
  onReviewTotalSymbol: function ({ detail: featureId }: CustomEvent) {
    // FIXME: be careful this offset ratio!
    // @2023/10/12, 17
    const ratio = 3 / 16; // dont larger than this! - @2023/10/17
    // center feature to the left part of map box(viewport)
    const mapSize = this.map().size as Size;
    const offsetX = mapSize[0] * ratio;
    const offsetY = mapSize[1] / 2;
    this.map().flyToFeature(featureId, offsetX, offsetY);
  },
  onReviewFittingFocus: function ({ detail: featureId }: CustomEvent) {
    this.map().flyToFeature(featureId);
  },
  onFeatureAutoMode: function ({ detail }: CustomEvent) {
    this.map().setFeatureMode(detail);
  },
  onFeatureMoveMode: function ({ detail }: CustomEvent) {
    this.map().switchToFeatureTranslate();
    this.map().setFeatureMode(detail);
  },
  onFeatureModifyMode: function ({ detail }: CustomEvent) {
    this.map().switchToFeatureModify();
    this.map().setFeatureMode(detail);
  },
  /**
   * Accept delete event only!
   */
  onFeatureDelete: function ({ detail: id }: CustomEvent) {
    this.map().deleteFeatureBy(id);
    relayer.clearSymbolFromStore(this.pageId(), id);
  },
  /** delete multiple features */
  onDeleteAll: function (symbolIds: string[]) {
    const symbols = this.map().deleteFeatures(symbolIds);
    relayer.clearSymbolsFromStore(this.pageId(), symbols);
  },
  /**
   * Copy feature from `properties panel`
   */
  onFeatureCopy: function (id: string) {
    const symbolCopy = this.map().copyFeature(id);
    if (!symbolCopy) return warn(`## No symbol copy created!`);
    relayer.addSymbolToStore(this.pageId(), symbolCopy);
  },
  confirmSymbolHandler: function ({ detail }: CustomEvent) {
    const { id, silent } = detail;
    this.map().correctSymbolSilent({ id, [UVF]: 1 }, 0);
    // If do confirmation from review table, no need to refresh feature panel!
    // @2024/12/03
    if (silent) return;
    this.map().refreshFeatureDetail(id);
  },
  /**
   * correct symbol with one field
   * details: {
      id,
      [field]: value,
      completed
    };
   */
  correctSymbolSientHandler: function ({ detail }: CustomEvent) {
    const singleFieldValue = detail as GO;
    this.map().correctSymbolSilent(singleFieldValue);
  },
  batchCorrectionHandler: function ({ detail }: CustomEvent) {
    // got a detail list including `symbols` & `page`
    // console.log(detail);
    this.map().syncBatchFeatureDetail(detail as GO[]);
  },
  /** @deprecated */
  correctElementHandler: function (event: CustomEvent) {
    // const feObject = event.detail as FittingEntity;
    // this.map().correctSymbolWith(feObject);
  },
  applyBrushSettingHandler: function (event: Event) {
    const { detail } = event as CustomEvent;
    this.map().setBrushSetting(detail);
    // remove previous tools setting first...
    this.map().stopAllToolBeforeSwitch(true);
    const brush = document.querySelector('.brush-indicator');
    if (!brush) return warn(`## No brush element existance!`);
    this.map().openBrushPainting(brush as HTMLElement);
  },
  newLineHandler: function (event: Event) {
    const { detail } = event as CustomEvent;
    // remove previous tools setting first...
    this.map().stopAllToolBeforeSwitch(false);
    // remember detail, to check tool type later - @2023/12/20
    this.map().setFittingEntity(detail);
    this.map().setNewLineTool(detail);
    this.map().stopDrawDots();
  },
  newElementHandler: function (event: CustomEvent) {
    const feObject = event.detail as FittingEntity;
    // remove previous tools setting first...
    this.map().stopAllToolBeforeSwitch(false);
    // display drawer dot
    const drawer = document.querySelector('.map-dot-drawer');
    // cache feobj...
    this.map().setFittingEntity(feObject);
    this.map().openDrawingDots(drawer as HTMLElement);
    this.map().removeDrawInteraction();
  },
  zoomByLevelHandler: function (event: CustomEvent) {
    const level = event.detail;
    if (level === 'max') return this.map().zoomToMax();
    this.map().zoomBy(level);
  },
  zoomInHandler: function (event: Event) {
    this.map().zoomIn();
  },
  zoomOutHandler: function (event: Event) {
    this.map().zoomOut();
  },
  domContextMenuHandler: function (event: MouseEvent) {
    domContextMenuHandler(this.map(), this.locked())(event);
  },
  onDomClickHandler: function () {
    const mCtxMenu = document.querySelector('.map-context-menu');
    mCtxMenu?.classList.add('hidden');
  },
  isCtrlKey: function (event: KeyboardEvent) {
    return (
      event.key === KEY_CODES.CONTROL ||
      event.key === KEY_CODES.META ||
      event.ctrlKey
    );
  },
  keyupHandler: function (event: KeyboardEvent | FocusEvent) {
    // cancel ctrl key status
    this.map().onCtrlKeyReleased(event);

    const currentElement = event.target as HTMLElement;
    // FIXME: // no need to proceed keyboard event
    // @2024/07/05
    if (currentElement.tagName !== 'BODY') return;

    const isKbdEvent = event instanceof KeyboardEvent;
    if (!isKbdEvent) return; // windows blur captured, exit!

    // FIXME: here on keyup can ONLY use checking evetn.key equals to `Meta`
    // instead of `event.metaKey`, its `false` !!! Unbelievable!
    // @2023/12/21(MY BIRTHDAY)
    const isCtrlKey = this.isCtrlKey(event);
    const isDrawingLength = this.map().isDrawing();
    // FIXME: need to check if drawing multiple lines, otherwise cause bug
    // @2024/01/31
    const isLastElbow = this.map().isLastSymbolElbow();

    // delete last fitting for multiple pipe
    if (isCtrlKey && isDrawingLength && isLastElbow) {
      // delete last fitting symbol at the end of pipe ...
      this.map().undoLastSymbolAddition();
      // finish one session of multiple-line drawing
      this.map().finishMultipleLines();
    }
  },
  /**
   * FIXME: only working in `editing` mode, that means do not listen key event while review panel open!
   * @2024/09/26
   *
   * Key down handler only towards editor(drawing) related operation,
   * Do not process other popups and side panels
   *
   * NOTE: another `Escape` keyDownHandler is in `useTotalReviewOpener`
   *
   * @log need to add active line to drawing session while pressed Ctrl key!
   * @date 2024/07/05
   *
   * @param event
   * @returns
   */
  keydownHandler: function (event: KeyboardEvent) {
    // first safety check:
    if (!event.target) return;

    // FIXME: check global lock - @2024/09/25
    if (this.locked()) return;

    const unknowElement = event.target as HTMLElement;
    const isBodyElement = unknowElement.tagName == 'BODY';
    // check event source, only be `BODY` to proceed!
    if (!isBodyElement) return;

    // FIXME: check current edit mode, `edit` or `review`
    // ignore keydown event for `review` mode! - @2024/09/26
    // @deprecated this locig - 2024/12/10
    // if (this.isReviewPanelOpen()) return;

    // Ctrl or CMD key pressed
    if (this.isCtrlKey(event)) {
      this.map().onCtrlKeyPressed();
      // === Add active line to drawing session ===
      this.map().preExtendingLine();
    }
    // close panel & stop drawing symbol!
    if (event.key === KEY_CODES.ESCAPE) {
      // FIXME: do nothing while disabled checked - @2024/05/29
      if (this.map().isFeaturesDisabled()) return;

      // FIXME: rerender all the symbols after `total review panel` closed,
      // and zoom out to a bigger view - 2024/01/30
      // FIXME: do not zoom-out while drawing line
      // @2024/02/06
      if (!this.map().isDrawing()) {
        this.map().showAll(this.symbols());
        // 2.4 is perfect for my browser to restore to the original level - 2024/02/02
        // NO need to zoom-out, it lose focus!
        // map.zoomBy(2.4);
      }
      this.map().removeDrawInteraction();
      this.map().stopDrawDots();
      this.map().stopBrushPainting();
      this.map().deselectActiveFeatures(); // 2023/12/20
      return;
    }
    // NOTE: Mac use: Backspace, Win use: Delete
    // @2024/09/26
    // FIXME: No need to check event target cos the review panel closed at this point!
    // @2024/10/01
    if (event.key === KEY_CODES.BACKSPACE || event.key === KEY_CODES.DELETE) {
      const toDeleted = this.map().getActiveFeatures();
      // empty check
      if (!toDeleted.length) {
        return console.warn('## No features selected!');
      }

      // === single selection process
      if (toDeleted.length === 1) {
        const featureId = this.map().deleteSelectedFeature() as string;
        // FIXME: check if has changed, in order to figure out if new symbols being deleted
        const hasChanged = this.map().hasChanged();
        // step 2: update smbols store is necessary for changes check on page leave
        relayer.clearSymbolFromStore(this.pageId(), featureId, hasChanged);
        return;
      }

      // === multiple selection process
      const symbolIds = toDeleted.map((f) => f.getId() as string);
      const symbols = this.map().deleteFeatures(symbolIds);
      // FIXME: check if has changed, in order to figure out if new symbols being deleted
      const hasChanged = this.map().hasChanged();
      // notify `useFileDetails` to clear cache
      relayer.clearSymbolsFromStore(this.pageId(), symbols, hasChanged);
    }
  },
};
