import { Popover } from '@headlessui/react';
import { CloudArrowUpIcon, CpuChipIcon } from '@heroicons/react/24/outline';

import {
  SUMMARY_TOOL_MENU_CSV as CSV,
  SUMMARY_TOOL_MENU_XLS as XLS,
  SUMMARY_TOOL_MENU_PRJ as PRJ,
} from '@/config';

import { SUMMARY_XP_FORMAT as FMT } from '../types';

type ExportToolProps = {
  exportTo: (format: string) => void;
};

export const ExportDrawingTool = ({ exportTo }: ExportToolProps) => (
  <Popover className="relative">
    <Popover.Button className="btn-title-tool mr-1">
      <CloudArrowUpIcon className="size-4 text-blue-600 inline-block" />
      <span className="text-sm px-1">Export</span>
    </Popover.Button>

    <Popover.Panel className="absolute z-500 ">
      <ul className="btn-popup-menu">
        <Popover.Button
          className="btn-popup-menu-item border-b border-gray-300 text-xs text-left p-2"
          onClick={() => exportTo(FMT.C)}
        >
          {CSV}
        </Popover.Button>
        <Popover.Button
          className="btn-popup-menu-item border-b border-gray-300 text-xs text-left p-2"
          onClick={() => exportTo(FMT.X)}
        >
          {XLS}
        </Popover.Button>
        <Popover.Button
          className="btn-popup-menu-item border-b border-gray-300 text-xs text-left p-2"
          onClick={() => exportTo(FMT.T)}
        >
          {PRJ}
        </Popover.Button>
        {/* <Popover.Button className="btn-popup-menu-item">PDF</Popover.Button> */}
      </ul>
    </Popover.Panel>
  </Popover>
);

export const EstimatingTool = () => (
  <Popover aria-disabled className="relative">
    <Popover.Button disabled className="btn-title-tool mr-8">
      <CpuChipIcon className="size-4 text-blue-600 inline-block" />
      <span className="text-sm px-1">Estimating API</span>
    </Popover.Button>

    <Popover.Panel className="absolute z-500 ">
      <ul className="btn-popup-menu">
        <li className="btn-popup-menu-item border-b border-gray-300">
          Autodesk
        </li>
        <li className="btn-popup-menu-item border-b border-gray-300">
          Trimble
        </li>
        <li className="btn-popup-menu-item">Quotesoft</li>
      </ul>
    </Popover.Panel>
  </Popover>
);
