/**
 * filter data collection to get part of that
 * @date 2024/10/18
 */

import * as _ from 'lodash-es';

import { iconFinderXternal } from '../store';
import {
  BPSymbol,
  SYMBOL_MODE,
  SymbolFindResult,
  LENGTH_TYPE,
  SPECIAL_JOB_ITEM as SPI,
  FILTERS,
  CATALOGUE_CATEGORY,
  CATALOGUE_TYPE,
} from '../types';

/**
 * DUCT|FITTING filter
 * @param smb symbol
 * @returns
 */
export const ductFittingFilter = (smb: BPSymbol) =>
  smb.category === 'DUCT' && smb.detail.itemType == 'FITTING';
/**
 * PIPE|FITTING filter
 * @param smb symbol
 * @returns
 */
export const pipeFittingFilter = (smb: BPSymbol) =>
  smb.category === 'PIPE' && smb.detail.itemType == 'FITTING';
/**
 * DUCT|LENGTH filter
 * FIXME: update `category` property for duct_length
 * @param smb symbol
 * @returns
 */
export const ductLengthFilter = (smb: BPSymbol) => {
  if (smb.class === SPI.RECT_DUCT) return true;
  return smb.category === LENGTH_TYPE.D;
};
/**
 * PIPE|LENGTH filter
 * FIXME: update `category` property for pipe_length
 * @param smb symbol
 * @returns
 */
export const pipeLengthFilter = (smb: BPSymbol) =>
  smb.category === LENGTH_TYPE.P;
/**
 * EQUIPMENT filter
 * @param smb symbol
 * @returns
 */
export const equipmentsFilter = (smb: BPSymbol) =>
  smb.category === CATALOGUE_CATEGORY.E;
/**
 * PLUMBING|FITTING filter
 * @param symbol symbol
 * @returns
 */
export const plumbingFilter = (symbol: BPSymbol) => {
  const isPlumbing = symbol.category === CATALOGUE_CATEGORY.PLM;
  const isFitting = symbol.detail['itemType'] === CATALOGUE_TYPE.F;
  return isPlumbing && isFitting;
};

export const symbolToFR = (smbl: BPSymbol): SymbolFindResult => {
  const icon = iconFinderXternal(_.snakeCase(smbl.class));
  const hashStr = smbl.index.toString();
  const system = smbl.detail['system'] || '';
  return { ...smbl, icon, hash: hashStr, system };
};

const symbolToListItems = (results: BPSymbol[]): SymbolFindResult[] => {
  return results.map(symbolToFR);
};

/**
 * filter all symbols with specific type
 * FIXME: but for equipment it represent equipment `name` - 2023/05/26
 *
 * @param type_or_name item type or equipment name
 * @returns
 */
const filteringSymbolsBy = (
  type_or_name: string,
  symbols: BPSymbol[],
): SymbolFindResult[] => {
  const results = symbols.filter((symbol) => {
    const isTypeMatch = symbol.class === type_or_name;
    const isNameMatch = symbol.detail?.['name'] === type_or_name;
    return isTypeMatch || isNameMatch;
  });
  if (type_or_name === 'ALL') return symbolToListItems(symbols);
  return symbolToListItems(results);
};

/**
 * filter symbols just by item type in review table
 *
 * @param type item type
 * @param symbols
 * @returns
 */
export const pickingSymbolJustBy = (type: string, symbols: BPSymbol[]) => {
  const visibleFilter = (smb: BPSymbol) => smb.mode !== SYMBOL_MODE.DEL;
  const visibleSymbols = symbols.filter(visibleFilter);
  return filteringSymbolsBy(type, visibleSymbols);
};

/**
 * Filter out symbol rows with first level filter in total review panel
 * @param category filter type of first dropdown select such as: DUCT|FITTING
 * @param symbols source symbols
 * @returns filtered result
 */
export const pickupSymbolsByFilter = (
  category: string,
  symbols: BPSymbol[],
): SymbolFindResult[] => {
  const visibleFilter = (smb: BPSymbol) => smb.mode !== SYMBOL_MODE.DEL;
  const visibleSymbols = symbols.filter(visibleFilter);

  // setup dynamic filter by group
  const groupFilter: { [key: string]: (smb: BPSymbol) => boolean } = {
    [FILTERS.DUCTS]: ductFittingFilter,
    [FILTERS.PIPES]: pipeFittingFilter,
    [FILTERS.DLENT]: ductLengthFilter,
    [FILTERS.PLENT]: pipeLengthFilter,
    [FILTERS.EQMT]: equipmentsFilter,
    [FILTERS.PLMBS]: plumbingFilter,
  };
  const nothing = () => false;
  const safeFilter = groupFilter[category] || nothing;
  const allSymbolsInGroup = visibleSymbols.filter(safeFilter);
  return symbolToListItems(allSymbolsInGroup);
};

/**
 * While total correction group selected and subtype is `All`, need all the items of the group
 * @deprecated no longer useful because of `group` changed to `filter` - 2024/11/18
 * @param group
 * @param symbols
 * @returns
 */
export const pickingSymbolsByGroup = (group: string, symbols: BPSymbol[]) => {
  const visibleFilter = (smb: BPSymbol) => smb.mode !== SYMBOL_MODE.DEL;
  const visibleSymbols = symbols.filter(visibleFilter);
  // FIXME: do not need to consider symbol type, regardless `rect` or `circle`
  // @2024/04/10
  const fitingDuctFilter = (smb: BPSymbol) =>
    smb.category === CATALOGUE_CATEGORY.D;
  const fittingPipeFilter = (smb: BPSymbol) =>
    smb.category === CATALOGUE_CATEGORY.P;
  const lengthDuctFilter = (smb: BPSymbol) => {
    if (smb.class === SPI.RECT_DUCT) return true;
    return smb.category === LENGTH_TYPE.D;
  };
  const lengthPipeFilter = (smb: BPSymbol) => smb.category === LENGTH_TYPE.P;
  const equipmentsFilter = (smb: BPSymbol) =>
    smb.category === CATALOGUE_CATEGORY.E;

  // setup dynamic filter by group
  const groupFilter: { [key: string]: (smb: BPSymbol) => boolean } = {
    [CATALOGUE_CATEGORY.D]: fitingDuctFilter,
    [CATALOGUE_CATEGORY.P]: fittingPipeFilter,
    [LENGTH_TYPE.D]: lengthDuctFilter,
    [LENGTH_TYPE.P]: lengthPipeFilter,
    [CATALOGUE_CATEGORY.E]: equipmentsFilter,
  };
  const nothing = () => false;
  const safeFilter = groupFilter[group] || nothing;
  const allSymbolsInGroup = visibleSymbols.filter(safeFilter);
  return symbolToListItems(allSymbolsInGroup);
};
