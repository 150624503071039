import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { log, warn } from '@/utils/logger';

import { useUser } from './auth';

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const user = useUser();
  const location = useLocation();

  // FIXME: parse shared drawing url to redirect after login
  // @2024/08/07
  const sharedURL = location.pathname + location.search;
  if (!user.data) {
    warn(`## ProtectedRoute: user object is empty!`);
    return (
      <Navigate
        to={`/auth/login?redirectTo=${encodeURIComponent(sharedURL)}`}
        replace
      />
    );
  }

  return children;
};
