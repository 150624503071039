import clsx from 'clsx';
import * as React from 'react';

import { Spinner } from '../spinner';

// export const ActionButton = ({ onClick }: any) => (
//   <button type="button" className="focus:outline-none" onClick={onClick}>
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       className="size-5"
//       viewBox="0 0 20 20"
//       fill="currentColor"
//     >
//       <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
//     </svg>
//   </button>
// );

const variants = {
  primary: 'bg-primary-blue text-white border-none hover:bg-primary-over',
  success: 'bg-green text-white border-none hover:bg-light-green',
  inverse: 'bg-white text-blue-600 hover:bg-blue-600:text-white',
  danger: 'bg-red-600 text-white hover:bg-red-50:text-red-600',
  'danger-outline':
    'bg-white text-primary-red border-primary-red hover:text-light-red hover:border-light-red font-bold',
};

const sizes = {
  sm: 'py-2 px-4 text-sm',
  md: 'py-2 px-6 text-md',
  lg: 'py-3 px-8 text-lg',
};

type IconProps =
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export type IButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isLoading?: boolean;
} & IconProps;

export const TButton = React.forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      size = 'md',
      isLoading = false,
      startIcon,
      endIcon,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          'flex justify-center items-center border border-gray-300 disabled:opacity-70 disabled:cursor-not-allowed rounded-md shadow-sm font-medium focus:outline-none transition-all',
          variants[variant],
          sizes[size],
          className,
        )}
        {...props}
      >
        {isLoading && <Spinner size="sm" className="text-current" />}
        {!isLoading && startIcon}
        <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
      </button>
    );
  },
);

TButton.displayName = 'tButton';
